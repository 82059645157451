import React, { useState, useEffect } from 'react';
import axios from '../../apiConfig';

import { useStage } from '../../Contexts/StageContext';
import { useDashboard } from '../../Contexts/DashboardContext';
import { useAccountData } from '../../Contexts/AccountDataContext';
import { useClientData } from '../../Contexts/ClientDataContext';

import CLIENT_HEADER from './CLIENT_HEADER';
import ACCOUNTS from './Accounts/ACCOUNTS';
import ACCOUNT_DETAILS from './Accounts/ACCOUNT_DETAILS';
import INVOICE_HISTORY from './Invoices/INVOICE_HISTORY';
import BILLING from './Payments/BILLING';

import './CLIENT_DASHBOARD.css';

const loadStripeScript = () => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.async = true;
    document.body.appendChild(script);
};

//client main view
const CLIENT_DASHBOARD = () => {

    const { invoices, accounts, setAccounts } = useClientData();
    const { stage, setStage } = useStage();
    const { dashboard, setDashboard } = useDashboard();
    const { selectedAccount } = useAccountData();

    useEffect(() => {
        loadStripeScript();
        setDashboard('ACCOUNTS');
        fetchAccounts();
    }, [])

    useEffect(() => {
    }, [accounts]);

    const fetchAccounts = async () => {
        try {
            const response = await axios.get('/client/accounts');
            setAccounts(response.data);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    const renderContent = () => {
        switch(dashboard) {
            case 'ACCOUNTS':
                return (
                    <ACCOUNTS accounts={accounts} />
                );
            case 'ACCOUNT_DETAILS':
                return(
                    <ACCOUNT_DETAILS account={selectedAccount} />
                )
            case 'INVOICE_HISTORY':
                return(
                    <INVOICE_HISTORY />
                )
            case 'BILLING':
                return(
                    <BILLING accounts={accounts} />
                )
            default:
                return null;
        }
    }

    return (
        <div className='dashboard_container'>
            <CLIENT_HEADER />
            <div className='component_container'>
                {renderContent()}
            </div>
        </div>
    )
}

export default CLIENT_DASHBOARD;