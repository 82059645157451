import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';


import FIRST_SECTION from './Main_Page_Components/First_section/FIRST_SECTION.js';
import SECOND_SECTION from './Main_Page_Components/Second_Section/SECOND_SECTION.js';
import THIRD_SECTION from './Main_Page_Components/Third_Section/THIRD_SECTION.js';
import logo from '../../Images/honeydew_logo.png';

import './LAUNCH_MAIN.css';
import header_styles from './LAUNCH_HEADER.module.css';
import footer_styles from './Footer.module.css';

const LAUNCH_MAIN = () => {
    const [activeLink, setActiveLink] = useState('');

    const firstSectionRef = useRef(null);
    const secondSectionRef = useRef(null);
    const thirdSectionRef = useRef(null);

    const Footer = () => {
        return (
            <div className={footer_styles.container} >

            </div>
        )
    }

    return (
    <div className='main-page-container'>
        <div className='section-container'>
            <div className={header_styles.container}>
                <div className={header_styles.quarter}>
                    {/* <img src={logo} alt='Sharps-logo' className={header_styles.logo}></img> */}
                </div>
                <div className={header_styles.half}>
                <div className={header_styles.link_container}>
                        <Link className={activeLink === 'login' ? `${header_styles.active}` : `${header_styles.link}`} to="/" onClick={() => {
                            firstSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                            setActiveLink('login');
                        }}>Login</Link> 
                    </div>
                    <div className={header_styles.link_container}>
                        <Link className={activeLink === 'section2' ? `${header_styles.active}` : `${header_styles.link}`} to="/" onClick={() => {
                            secondSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            setActiveLink('section2');
                    }}>About</Link> 
                    </div>
                    <div className={header_styles.link_container}>
                        <Link className={activeLink === 'section3' ? `${header_styles.active}` : `${header_styles.link}`} to="/" onClick={() => {
                            thirdSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            setActiveLink('section3');
                    }}>Benefits</Link> 
                    </div>
                </div>
                <div className={header_styles.quarter}>
                    
                </div>
            </div>

            <div ref={firstSectionRef}>
                <FIRST_SECTION />
            </div>
            <div ref={secondSectionRef}>
                <SECOND_SECTION />
            </div>
            <div ref={thirdSectionRef}>
                <THIRD_SECTION />
            </div>
            <Footer />
        </div>
        
    </div>
    )
};

export default LAUNCH_MAIN;