import React, { useEffect, useState } from 'react';

import { useComponent } from '../../../../Contexts/ComponentContext';
import { useServiceData } from '../../../../Contexts/ServiceDataContext';

import ACCOUNT_LIST from './Modules/ACCOUNT_LIST';
import ACCOUNT_NOTIFS from './Modules/ACCOUNT_NOTIFS';
import FORM_UPDATE_SERVICE from './Modules/FORM_UPDATE_SERVICE';

import styles from './SERVICE_MGMT.module.css';

const SERVICE_MGMT = () => {

    const { accountsListData, clients, accounts, fetchAccountsListData } = useServiceData();
    const { component, setComponent } = useComponent();

    const [selectedAccount, setSelectedAccount] = useState(null);

    useEffect(() => {
        setComponent('INCOMPLETE');
    }, [setComponent]);

    return (
        <div className={styles.SERVICE_MGMT_container_outer}>
            <div className={styles.SERVICE_MGMT_container_inner}>
                <ACCOUNT_LIST component={component} setComponent={setComponent} accountsListData={accountsListData} setSelectedAccount={setSelectedAccount} />
                {selectedAccount && (
                    <div className={styles.account_details_container}>
                        <FORM_UPDATE_SERVICE account={selectedAccount} />
                        <ACCOUNT_NOTIFS />
                    </div>
                )}
            </div>
        </div>
    )
}

export default SERVICE_MGMT;