import React, { useState, useEffect } from 'react';
import axios from '../../../apiConfig';

import './INVOICE_HISTORY.css';

//renders a full history of invoices related to the client, and the related services
const INVOICE_HISTORY = () => {
    const [invoices, setInvoices] = useState([]);
    const [accounts, setAccounts] = useState({});
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [expandedItems, setExpandedItems] = useState({});

    useEffect(() => {
        const fetchInvoicesAndAccounts = async () => {
            try {
                const response = await axios.get('/client/invoices_RelatedServices_Accounts');
                console.log('Invoices response: ', response);
                setInvoices(response.data.invoicesWithServices);
                setAccounts(response.data.accounts.reduce((acc, account) => {
                    acc[account.account_id] = account;
                    return acc;
                }, {}));
            } catch (error) {
                console.error('Error fetching invoices:', error);
            }
        };
        fetchInvoicesAndAccounts();
    }, []);

    const handleInvoiceClick = (invoice) => {
        setSelectedInvoice(invoice);
    };

    const toggleServiceExpansion = (serviceId) => {
        setExpandedItems(prevItems => ({
            ...prevItems,
            [serviceId]: !prevItems[serviceId]
        }));
    };

    return (
        <div className="invoice_history_container">
            <div className="invoices_list">
                {invoices.map(invoice => (
                    <div key={invoice.invoice_id} onClick={() => handleInvoiceClick(invoice)} className="invoice_entry">
                        Invoice #{invoice.invoice_id} - ${parseFloat(invoice.charge_outstanding).toFixed(2)} | {new Date(invoice.date_issued).toLocaleDateString()}
                    </div>
                ))}
            </div>
            <div className="invoice_details">
                {selectedInvoice && (
                    <div>
                        <h3>Invoice Details - #{selectedInvoice.invoice_id}</h3>
                        {selectedInvoice.services?.length > 0 && selectedInvoice.services.map(service => (
                            <div 
                                key={service.service_id} 
                                className={`invoice_item ${expandedItems[service.service_id] ? 'expanded' : ''}`}
                                onClick={() => toggleServiceExpansion(service.service_id)}
                            >
                                <p>Service: {service.service_type} on {new Date(service.timestamp).toLocaleDateString()}</p>
                                {expandedItems[service.service_id] && (
                                    <div className="invoice_item_details">
                                        <p>Account: {accounts[service.account_id]?.street_address}</p>
                                        <p>Description: {service.description || 'No description'}</p>
                                        <p>Amount: ${parseFloat(service.amount_charged).toFixed(2)}</p>
                                        <p>Outstanding: ${parseFloat(service.charge_outstanding).toFixed(2)}</p>
                                        <p>Status: {parseFloat(service.charge_outstanding) === 0.00 ? 'Paid' : 'Not Paid'}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default INVOICE_HISTORY;
