// EXTERNAL
import React, { useState, useEffect } from 'react';

// INTERNAL
import axios from '../../../apiConfig';
import { useClientData } from '../../../Contexts/ClientDataContext';
import FORM_PAYMENT from './FORM_PAYMENT';
import './BILLING.css';

//displays unpaid or partially paid invoices and their respective services. Stores selected services for the payment form.
const BILLING = () => {
  const { unpaidInvoices, setUnpaidInvoices } = useClientData(); 
  const [selectedServices, setSelectedServices] = useState({});
  const [selectedInvoices, setSelectedInvoices] = useState({});
  const [paymentAmount, setPaymentAmount] = useState(0);

  useEffect(() => {
      console.log('Payment Amount:', paymentAmount);
      console.log('Selected Services State:', selectedServices);
  }, [paymentAmount, selectedServices]);

  const toggleServiceSelection = (serviceId, amount) => {
    setSelectedServices(prev => {
        const newSelected = { ...prev };
        if (newSelected[serviceId]) {
            delete newSelected[serviceId];
        } else {
            newSelected[serviceId] = amount;
        }
        calculatePaymentAmount(newSelected, selectedInvoices);
        return newSelected;
    });
  };
  
  const toggleInvoiceSelection = (invoiceId, services) => {
      setSelectedInvoices(prevInvoices => {
          const newSelectedInvoices = { ...prevInvoices };
          let newSelectedServices = { ...selectedServices };
  
          if (newSelectedInvoices[invoiceId]) {
              delete newSelectedInvoices[invoiceId];
              services.forEach(service => {
                  delete newSelectedServices[service.service_id];
              });
          } else {
              newSelectedInvoices[invoiceId] = true; // Just mark the invoice as selected
              services.forEach(service => {
                  newSelectedServices[service.service_id] = parseFloat(service.charge_outstanding);
              });
          }
          setSelectedServices(newSelectedServices); // Update the services state here
          calculatePaymentAmount(newSelectedServices);
          return newSelectedInvoices;
      });
  };

  const getSelectedServiceIds = () => {
      return Object.keys(selectedServices);
  };

  const calculatePaymentAmount = (services) => {
      let total = Object.values(services).reduce((sum, amount) => sum + amount, 0);
      setPaymentAmount(total);
  };

  const handlePayment = async () => {
    try {
        const response = await axios.get('/client/unpaidInvoicesWithRelatedServices');
        setUnpaidInvoices(response.data || []);
    } catch (error) {
        console.error('Error refreshing unpaid invoices:', error);
    }
  };

  const resetPaymentAmount = () => {
    setPaymentAmount(0);
  };

  return (
    <div className="billing_container">
      <div className="invoices_services_overview">
          <h3>Invoices and Services</h3>
          {Array.isArray(unpaidInvoices.invoices) && unpaidInvoices.invoices.length > 0 ? (
          unpaidInvoices.invoices.map(invoice => (
            <div 
                key={invoice.invoice_id} 
                className={`invoice_item ${selectedInvoices[invoice.invoice_id] ? 'selected' : ''}`}
                onClick={() => toggleInvoiceSelection(invoice.invoice_id, invoice.services)}
            >
            <h4>Invoice #{invoice.invoice_id} - ${parseFloat(invoice.charge_outstanding).toFixed(2)}</h4>
            {invoice.services.map(service => (
                <div 
                    key={service.service_id} 
                    className={`service_item ${selectedServices[service.service_id] ? 'selected' : ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleServiceSelection(service.service_id, parseFloat(service.charge_outstanding));
                    }}
                >
                    {service.service_type} - ${parseFloat(service.charge_outstanding).toFixed(2)} | {new Date(service.timestamp).toLocaleDateString()}
                </div>
          ))}
        </div>
      ))
  ) : (
        <div className="no_unpaid_invoices">All Services Paid</div>
  )}
      </div>
      <div className="payment_form">
          <h3>Make a Payment</h3>
          <div>Payment Amount: ${paymentAmount.toFixed(2)}</div>
          <FORM_PAYMENT 
              paymentAmount={paymentAmount * 100}
              selectedServiceIds={getSelectedServiceIds()}
              onPaymentSuccess={handlePayment}
              resetPaymentAmount={resetPaymentAmount}
          />
      </div>
    </div>
  );
};

export default BILLING;
