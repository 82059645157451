const getCurrentBusinessCycle = (date = new Date()) => {
    try {
        date.setHours(0, 0, 0, 0);
        const diff = date.getDay() === 0 ? 7 : date.getDay();
        date.setDate(date.getDate() - diff + 1);

        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
        // Get the day of the week, month, day of the month, and year from the date object
        const dayName = days[date.getDay()];
        const monthName = months[date.getMonth()];
        const dayOfMonth = date.getDate();
        const year = date.getFullYear();

        const endOfBusinessCycle = new Date(date.setDate(date.getDate() + 6));
        const endDayName = days[endOfBusinessCycle.getDay()];
        const endMonthName = months[endOfBusinessCycle.getMonth()];
        const endDayOfMonth = endOfBusinessCycle.getDate();
        const endYear = endOfBusinessCycle.getFullYear();
    
        // Function to add suffix to day (1st, 2nd, 3rd, etc.)
        function getDaySuffix(day) {
        if (day > 3 && day < 21) return 'th'; // for 4th to 20th
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
        }
    
        // Format the hours and minutes for the time
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'P.M.' : 'A.M.';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    
        // Combine all parts into the final formatted string
        return `${dayName}, ${monthName} ${dayOfMonth}${getDaySuffix(dayOfMonth)} - ${endDayName}, ${endMonthName} ${endDayOfMonth}${getDaySuffix(endDayOfMonth)}, ${endYear}`;

    } catch (getCurrentBusinessCycleError) {
        console.error("Error in getCurrentBusinessCycle: ", getCurrentBusinessCycleError);
    }
}

export default getCurrentBusinessCycle;