import React from 'react';
import styles from './formCreateAccount.module.css';


export default function FormCreateAccountUI({ handleReset, handleChange, handleSubmit, userInterfaceMessage, formData, clients, notes, setNotes }) {
    return (
        <div className={styles.outer_container}>
            <div className={styles.button_container}>
                <button type='button' className={`btn ${styles.button}`} onClick={handleReset}>Clear Form</button>
            </div>
            <div className={styles.form_container}>
                <form onSubmit={handleSubmit} className={`${styles.CLIENT_ACCOUNT_MGMT_form}`}>
                    <div className={styles.header_container}>
                        <h3 className={styles.header}>Account Information</h3>
                    </div>
                    {userInterfaceMessage && (<div>{userInterfaceMessage}</div>)}
                    <label className={`form-label ${styles.label}`} htmlFor='clientId'>
                        Client
                        <select className={`form-select ${styles.input}`} name="clientId" value={formData.clientId} onChange={handleChange} required>
                            {clients.map(client => (
                                <option key={client.user_id} value={client.user_id}>
                                    {client.first_name} {client.last_name}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='type'>
                        Type
                        <select className={`form-select ${styles.input}`} name="type" value={formData.type} onChange={handleChange} required>
                            <option value="Residential">Residential</option>
                            <option value="Commercial">Commercial</option>
                            <option value="HOA">HOA</option>
                        </select>
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='streetAddress'>
                        Street Address
                        <input type="text" className={`form-control ${styles.input}`} name="streetAddress" value={formData.streetAddress} onChange={handleChange} required />
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='zipCode'>
                        ZIP Code
                        <input type="text" className={`form-control ${styles.input}`} name="zipCode" value={formData.zipCode} onChange={handleChange} required />
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='city'>
                        City
                        <input type="text" className={`form-control ${styles.input}`} name="city" value={formData.city} onChange={handleChange} required />
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='county'>
                        County
                        <input type="text" className={`form-control ${styles.input}`} name="county" value={formData.county} onChange={handleChange} required />
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='state'>
                        State
                        <select className={`form-select ${styles.input}`} name="state" value={formData.state} onChange={handleChange} required>
                            <option value="KY">Kentucky</option>
                            <option value="IN">Indiana</option>
                        </select>
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='price'>
                        Price
                        <input type="text" className={`form-control ${styles.input}`} name="price" value={formData.price} onChange={handleChange} required />
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='active'>
                        Active
                        <input className={`form-control ${styles.input}`} name="active" placeholder="Defaults Active" value={formData.active} onChange={handleChange} />
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='serviceFrequency'>
                        Service Frequency
                        <input className={`form-control ${styles.input}`} name="serviceFrequency" placeholder="Service Frequency" value={formData.serviceFrequency} onChange={handleChange} />
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='notes'>
                        Additional Notes
                        <input type='text' className={`form-control ${styles.input}`} name='notes' value={notes} onChange={(e) => setNotes(e.target.value)} />
                    </label>
                    <div className={styles.create_button_container}>
                        <button type="submit" className={`btn ${styles.create_button}`}>Create Account</button>
                    </div>
                </form>
            </div>
        </div>
    )
}