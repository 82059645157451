import React, { useState, useEffect } from 'react';
import axios from '../../../../apiConfig';
import { useStage } from '../../../../Contexts/StageContext';
import { useUser } from '../../../../Contexts/UserContext';
import styles from './CSS/FORM_LOGIN.module.css';

const FORM_LOGIN = ({ handleReturn, setClientCreated }) => {

    const [credentials, setCredentials] = useState({
        username: '',
        password: ''
    });
    const [error, setError] = useState('');

    const { stage, setStage } = useStage();
    const { userType, setUserType, userId, setUserId } = useUser();

    
    useEffect(() => {
        setClientCreated(false);
    }, [setClientCreated]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCredentials(prev => ({ ...prev, [name]: value }));
    }
    
    //provide user their jwt upon successful login
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const { data } = await axios.post('/auth', credentials);
            if (data.token) {  
                localStorage.setItem('jwtToken', data.token);
            }
            switch (data.userType) {
                case 'client':
                case 'admin':
                    console.log(`Logged in as `, data.userType, ': ', data.userId);
                    setStage(data.userType.toUpperCase());
                    setUserType(data.userType);
                    setUserId(data.userId);
                    break;
                default:
                    alert('Unexpected server response. Login Failed.');
            }
        } catch (error) {
            // localStorage.removeItem('jwtToken');
            if (error.response && error.response.data) {
                console.log("Error: ", error.response.data);
                setError(error.response.data.message || error.response.data || 'Unexpected error.');
            } else {
                console.log("Error: ", error)
                setError('Network error, please try again later.');
            }
        }
    };
    

    return (
        <div className={styles.login_form_container}>
            <div className={styles.return_button_container}>
                <button className={`btn ${styles.return_button}`} onClick={() => handleReturn()}>Return</button>
            </div>
            <form onSubmit={handleSubmit} className={styles.login_form}>
                <div className={styles.input_container}>
                    <label className={` ${styles.input_label}`} htmlFor='username'>
                        Username
                    </label>
                    <input
                        className={`form-control ${styles.input_bar}`}
                        type='text'
                        name='username'
                        value={credentials.username}
                        onChange={handleChange}
                        required
                    />
                    
                </div>
                <div className={styles.input_container}>
                    <label className={` ${styles.input_label}`} htmlFor='password'>
                        Password
                    </label>
                    <input
                        className={`form-control ${styles.input_bar}`}
                        type='password'
                        name='password'
                        autoComplete="current-password"
                        value={credentials.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.login_button_container}>
                    <button 
                        className={`btn ${styles.login_button}`}
                        name='sign_in'
                        type='submit'
                    >Sign In</button>
                </div>
            </form>
            {error && <div className={styles.error_message}>{`Error : ${error}`}</div>}
        </div>
    ) 

}

export default FORM_LOGIN;