import { useConfirmation } from '../../Contexts/Confirmation';
import ConfirmationModal from '../../Utilities/Components/Confirmation';

import styles from './AdminBody.module.css';

export default function AdminBody({ children }) {
    const { popup } = useConfirmation();

    return (
        <div className={styles.component_container}>
            {popup && <ConfirmationModal />}
            {children}
        </div>
    )
}