import React, { useState } from 'react';
import axios from '../../../../../../apiConfig';
import { useConfirmation } from '../../../../../../Contexts/Confirmation';
import FormModifyClientOutstandingUI from './FormModifyClientOutstandingUI';

const FormModifyClientOutstanding = ({ clients, userId, userType, refreshBusinessData }) => {
    const [formData, setFormData] = useState({
        payment: 0,
        selectedClient: -1
    });
    const [outstandingDebt, setOutstandingDebt] = useState(0);

    const { togglePopup, setConfirmationData, setSubmitLogic, setLabels } = useConfirmation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'selectedClient') {
            const selectedClient = clients.find(client => client.user_id === parseInt(value));
            if (selectedClient) {
                setOutstandingDebt(selectedClient.outstanding_debt);
            } else {
                setOutstandingDebt(0);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setConfirmationData({ ...formData });
        setLabels(Object.keys(formData).map(key => ({ label: key, value: formData[key] })));
        setSubmitLogic(() => async () => {
            try {
                const result = await axios.post('/admin/modifyClientOutstanding', formData);
                console.log('Result of test: ', result);
                refreshBusinessData();
            } catch (error) {
                console.log('Error modifying client outstanding: ', error);
            }
        });
        togglePopup(true);
    };

    return (
        <FormModifyClientOutstandingUI
            formData={formData}
            clients={clients}
            outstandingDebt={outstandingDebt}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    );
}

export default FormModifyClientOutstanding;
