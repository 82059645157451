import React, { createContext, useContext, useState } from 'react';

export const ServiceDataContext = createContext();

import axios from '../apiConfig';

export const useServiceData = () => {
    const context = useContext(ServiceDataContext);
    if (!context) {
        throw new Error('useServiceData must be used within a ServiceDataProvider');
    }
    return context;
}

// Provides data to the admin relevant for business operations
export const ServiceDataProvider = ({ children }) => {
    const [clients, setClients] = useState([]);
    const [accounts, setAccounts] = useState ([]);
    const [invoices, setInvoices] = useState([]);
    const [accountsListData, setAccountsListData] = useState([]);
    const [invoiceReportOptions, setInvoiceReportOptions] = useState([]);
    const [accountReportOptions, setAccountReportOptions] = useState([]);

    const getData = async (routeURI, errorSubject, setState) => {
        try {
            const response = await axios.get(routeURI);
            setState(response.data);
        } catch (err) {
            console.error(`Error fetching ${errorSubject}:`, err);
        }
    };

    const fetchClients = async () => {
        getData('/admin/clients', 'clients', setClients);
    };

    const fetchAccounts = async () => {
        getData('/admin/accounts', 'accounts', setAccounts);
    };

    const fetchInvoices = async () => {
        getData('/admin/invoices', 'invoices', setInvoices);
    }

    const fetchAccountsListData = async () => {
        getData('/admin/accountsListData', 'accountsListData', setAccountsListData);
    };

    const fetchInvoiceReportOptions = async () => {
        getData('/admin/invoiceReportOptions', 'invoiceReportOptions', setInvoiceReportOptions);
    }

    const fetchAccountReportOptions = async () => {
        getData('/admin/accountReportOptions', 'accountReportOptions', setAccountReportOptions);
    }

    const refreshBusinessData = async () => {
        fetchClients();
        fetchAccounts();
        fetchInvoices();
        fetchAccountsListData();
    }

    return (
        <ServiceDataContext.Provider value={{ clients, accounts, invoices, accountsListData, invoiceReportOptions, accountReportOptions, fetchClients, fetchAccounts, fetchInvoices, fetchAccountsListData, fetchInvoiceReportOptions, fetchAccountReportOptions, refreshBusinessData }}>
            {children}
        </ServiceDataContext.Provider>
    );
};