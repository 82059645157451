import React, { useState } from 'react';
import { useAccountData } from '../../../Contexts/AccountDataContext';
import { useDashboard } from '../../../Contexts/DashboardContext';

import './ACCOUNTS.css';

//dynamically renders the accounts related to the client
const ACCOUNTS = ({ accounts }) => {
    const { setDashboard } = useDashboard();
    const { setSelectedAccount } = useAccountData();

    const [activeFilter, setActiveFilter] = useState('1');
    
    const handleAccountSelect = (account) => {
        setSelectedAccount(account);
        setDashboard('ACCOUNT_DETAILS');
    };

    const filteredAccounts = accounts.filter(account => account.active.toString() === activeFilter);

    return (
        <div className="accounts_container">
            <div className="accounts_filter">
                <button className={activeFilter === '1' ? 'active' : ''} onClick={() => setActiveFilter('1')}>Active Accounts</button>
                <button className={activeFilter === '0' ? 'active' : ''} onClick={() => setActiveFilter('0')}>Inactive Accounts</button>
            </div>
            {filteredAccounts.map(account => (
                <div 
                    key={account.account_id} 
                    className="account_square" 
                    onClick={() => handleAccountSelect(account)}
                >
                    <p><strong>Address:</strong> {account.street_address}</p>
                    <p><strong>City:</strong> {account.city}</p>
                    <p><strong>State:</strong> {account.state}</p>
                    <p><strong>Type:</strong> {account.type}</p>
                    <p><strong>Status:</strong> {account.status}</p>
                </div>
            ))}
        </div>
    );
};

export default ACCOUNTS;
