import React from 'react';
import styles from './modify_client_outstanding.module.css';

const Dropdown = ({ handleChange, formData, clients }) => (
    <div>
        <label className={`form-label ${styles.label}`} htmlFor='selectedClient'>
            Select Client:
        </label>
        <select className={`form-select form-select-lg mb-3 ${styles.input}`} name='selectedClient' onChange={handleChange} value={formData.selectedClient}>
            <option value='-1'>List of Clients</option>
            {clients.map(client => (
                <option key={client.user_id} value={client.user_id}>
                    {client.first_name} {client.last_name}
                </option>
            ))}
        </select>
    </div>
);

const FormModifyClientOutstandingUI = ({
    formData,
    clients,
    outstandingDebt,
    handleChange,
    handleSubmit
}) => {

    return (
        <div className={styles.outer_container}>
            <div className={styles.form_container}>
                <div className={styles.warning_message}>
                    {/* FILL THIS */}
                </div>
                <form onSubmit={handleSubmit}>
                    <div className={styles.header_container}>
                        <h3 className={styles.header}>Cash Payment</h3>
                    </div>
                    <Dropdown handleChange={handleChange} formData={formData} clients={clients} />
                    {formData.selectedClient !== -1 && (
                        <div className={styles.debt_info}>
                            <p>Outstanding Debt: ${outstandingDebt}</p>
                        </div>
                    )}
                    <label className={`form-label ${styles.label}`} htmlFor='payment'>
                        Payment:
                        <input type='text' className={`form-control ${styles.input}`} name='payment' value={formData.payment} onChange={handleChange} />
                    </label>
                    <div>
                        <button className={`btn ${styles.update_button}`} type='submit'>Credit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FormModifyClientOutstandingUI;
