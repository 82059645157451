import React from 'react';

import styles from './CSS/DropdownMenu.module.css';

const DropdownMenu = ({ isActive, toggleDropdown, setDashboard, setComponentName }) => {

    const directories = [
        { dashboard: 'SERVICE_MGMT', component: 'Service Management' },
        { dashboard: 'CLIENT_ACCOUNT_MGMT', component: 'Manage Clients & Accounts' },
        { dashboard: 'NOTIF', component: 'Notifications' },
        { dashboard : 'REPORT', component: 'Report Generation' }
    ];

    return (
    <div className={`${styles.dropdown} ${isActive ? styles.active : ''}`} onClick={toggleDropdown}>
        <div className={styles.dropdown_bar}></div>
        <div className={styles.dropdown_bar}></div>
        <div className={styles.dropdown_bar}></div>
        {isActive && 
        (
            <ul className={styles.dropdown_menu}>
                {directories.map((directory, index) => (
                    <li 
                    key={index} 
                    className={styles.menu_item} 
                    onClick= {
                        () => {
                            setDashboard(directory.dashboard);
                            setComponentName(directory.component); 
                            toggleDropdown(); 
                        }
                    }>{directory.component}
                    </li>
                ))}
            </ul>
        )}
    </div>
)};

export default DropdownMenu;
