import React from 'react';
import styles from './create_client_with_account.module.css';

const FormCreateClientWithAccountUI = ({
    formData,
    notes,
    userInterfaceMessage,
    toggleUserInterfaceMessageClass,
    handleChange,
    handleSubmit,
    clearForm,
    setNotes
}) => {
    return (
        <div className={styles.outer_container}>
            <div className={styles.button_container}>
                <button type="button" className={`btn ${styles.button}`} onClick={clearForm}>Clear Form</button>
            </div>
            <div className={styles.form_container}>
                <form onSubmit={handleSubmit} className={`${styles.CLIENT_ACCOUNT_MGMT_form}`}>
                    <div className={styles.client_container}>
                        <div className={styles.header_container}>
                            <h3 className={styles.header}>Client Information</h3>
                        </div>
                        <label htmlFor='firstName' className={`form-label ${styles.label}`}>
                            First Name
                            <input type="text" name="firstName" className={`form-control ${styles.input}`} value={formData.firstName} onChange={handleChange} required />
                        </label>
                        <label htmlFor='lastName' className={`form-label ${styles.label}`}>
                            Last Name
                            <input type="text" name="lastName" className={`form-control ${styles.input}`} value={formData.lastName} onChange={handleChange} required />
                        </label>
                        <label htmlFor='phoneNumber' className={`form-label ${styles.label}`}>
                            Phone Number
                            <input type="text" name="phoneNumber" className={`form-control ${styles.input}`} value={formData.phoneNumber} onChange={handleChange} required />
                        </label>
                        <label htmlFor='email' className={`form-label ${styles.label}`}>
                            Email
                            <input type="email" name="email" className={`form-control ${styles.input}`} value={formData.email} onChange={handleChange} required />
                        </label>
                    </div>

                    <div className={styles.header_container}>
                        <h3 className={styles.header}>Account Information</h3>
                    </div>
                    <div className={styles.account_container}>
                        <label htmlFor='type' className={`form-label ${styles.label}`}>
                            Type
                            <select name="type" className={`form-select ${styles.input}`} value={formData.type} onChange={handleChange} required>
                                <option value="Residential">Residential</option>
                                <option value="Commercial">Commercial</option>
                                <option value="HOA">HOA</option>
                            </select>
                        </label>
                        <label htmlFor='streetAddress' className={`form-label ${styles.label}`}>
                            Street Address
                            <input type="text" name="streetAddress" className={`form-control ${styles.input}`} value={formData.streetAddress} onChange={handleChange} required />
                        </label>
                        <label htmlFor='zipCode' className={`form-label ${styles.label}`}>
                            ZIP Code
                            <input type="text" name="zipCode" className={`form-control ${styles.input}`} value={formData.zipCode} onChange={handleChange} required />
                        </label>
                        <label htmlFor='city' className={`form-label ${styles.label}`}>
                            City
                            <input type="text" name="city" className={`form-control ${styles.input}`} value={formData.city} onChange={handleChange} required />
                        </label>
                        <label htmlFor='county' className={`form-label ${styles.label}`}>
                            County
                            <input type="text" name="county" className={`form-control ${styles.input}`} value={formData.county} onChange={handleChange} required />
                        </label>
                        <label htmlFor='state' className={`form-label ${styles.label}`}>
                            State
                            <select name="state" className={`form-select ${styles.input}`} value={formData.state} onChange={handleChange} required>
                                <option value="KY">Kentucky</option>
                                <option value="IN">Indiana</option>
                            </select>
                        </label>
                        <label htmlFor='price' className={`form-label ${styles.label}`}>
                            Price
                            <input type="text" name="price" className={`form-control ${styles.input}`} value={formData.price} onChange={handleChange} required />
                        </label>
                        <label htmlFor='serviceFrequency' className={`form-label ${styles.label}`}>
                            Service Frequency
                            <input name="serviceFrequency" className={`form-control ${styles.input}`} placeholder="Service Frequency" value={formData.serviceFrequency} onChange={handleChange} />
                        </label>
                        <label htmlFor='notes' className={`form-label ${styles.label}`}>
                            Additional Notes
                            <input type='text' name='notes' className={`form-control ${styles.input}`} value={notes} onChange={(e) => setNotes(e.target.value)} />
                        </label>
                    </div>
                    <button type="submit" className={`btn ${styles.submit_button}`}>Create Client and Account</button>
                </form>
            </div>
            {userInterfaceMessage && (
                <div className={`${styles.user_interface_message_container} ${styles[toggleUserInterfaceMessageClass]}`}>
                    {userInterfaceMessage}
                </div>
            )}
        </div>
    );
};

export default FormCreateClientWithAccountUI;
