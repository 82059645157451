// External
import React, { useState, useEffect } from 'react';

// Internal
import axios from '../../../../apiConfig';
import logActivationFormSubmission from '../../../../Utilities/Requests/activationMessage';

import styles from './CSS/FORM_CLIENT_ACTIVATION.module.css';

//form for activating client account
const FORM_CLIENT_ACTIVATION = ({ email, setClientCreated }) => {

    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: email
    });

    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPasswordToggle, setShowPasswordToggle] = useState(false);
    const [userInterfaceMessage, setUserInterfaceMessage] = useState('');
    const [toggleUserInterfaceMessageClass, setToggleUserInterfaceMessageClass] = useState('');
    const userInterfaceMessageClass = `user_interface_message_container ${toggleUserInterfaceMessageClass}`;

    useEffect(() => {}, [userInterfaceMessage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
        setUserInterfaceMessage('');
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setUserInterfaceMessage('');
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        let messageDetails = '';
        let messageError = '';
        let message = '';
        setUserInterfaceMessage('');

        if (formData.password !== confirmPassword) {
            setUserInterfaceMessage("Passwords do not match.");
            setToggleUserInterfaceMessageClass('error');
            return;
        }

        try {
            const { data } = await axios.post('/auth/activateClient', formData);
            setUserInterfaceMessage('Account created successfully!');
            setClientCreated(true);

        } catch (err) {
            if(err.response.status === 400) {
                setUserInterfaceMessage(err.response.data.message);
                messageDetails = `FORMATTING ERROR: Client activation for - ${email}.`;
                messageError = err.response.data.message;

            } else {
                messageDetails = `FAILED: Client activation for - ${email}.`
                messageError = err.response.data.message;

                if(err.response.status === 409) {
                    messageDetails = `FAILED: Client activation for - ${email}.`;

                }
            }
            const errorMessage = err.response?.data?.message || err.response.data.errors[0].msg ||'Error during account creation.';
            setUserInterfaceMessage(errorMessage);

        }

        message = `${messageDetails} ${messageError}`;
        const log = {
            subject: 'CLIENT ACTIVATION',
            details: message
        };

        logActivationFormSubmission(log);
    };

    return (
        <div className={styles.account_creation_form_container}>
            <form onSubmit={handleSubmit} className={styles.activate_form}>
                <div className={styles.input_container}>
                    <label className={` ${styles.input_label}`}>
                        Username:
                        <input 
                            type='text'
                            name='username'
                            value={formData.username}
                            onChange={handleChange}
                            required
                            className={`form-control ${styles.input_bar}`}
                        />
                    </label>
                </div>
                <div className={`${styles.input_container}`}>
                    <label className={` ${styles.input_label}`}>
                        Password:
                    <div className={`input-group mb-3 ${styles.input_container}`}>
                            <input
                                type={showPasswordToggle ? 'text' : 'password'}
                                name='password'
                                value={formData.password}
                                onChange={handleChange}
                                required
                                className={`form-control ${styles.input_bar}`}
                            />
                            <button className={`btn ${styles.password_button}`} type='button' onClick={() => setShowPasswordToggle(prev => !prev)}>
                                {showPasswordToggle ? 'Hide' : 'Show'}
                            </button>
                        </div>
                    </label>
                </div>
                <div className={styles.input_container}>
                    <label className={` ${styles.input_label}`}>
                        Confirm Password:
                        <input
                            type={showPasswordToggle ? 'text' : 'password'}
                            name='confirmPassword'
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                            className={`form-control ${styles.input_bar}`}
                        />
                    </label>
                </div>
                <div className={styles.activate_button_container}></div>
                    <button type='submit' className={`btn ${styles.activate_button}`} >Create Account</button>
                <div className={styles.activate_button_container}></div>
            </form>
            {userInterfaceMessage && <div className={styles.error_message}>{userInterfaceMessage}</div>}
        </div>
    )
}

export default FORM_CLIENT_ACTIVATION;