import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, BlobProvider } from '@react-pdf/renderer';
import logo from '../../../../../../Images/Logo-Design-Blacksmith-Revamp.png';
import getTodaysDate from '../../../../../../Utilities/Functions/getTodaysDate';
import { parseDate, parseTime } from '../../../../../../Utilities/Functions/parseDateTime';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        alignItems: 'center'
    },
    section: {
        marginTop: '.1in',
        marginBottom: '.1in',
        borderBottomWidth: 2,
        borderColor: 'black',
        borderStyle: 'solid',
        width: '90%',
    },
    logo: {
        width: '1.5in',
        height: '0.48in',
    },
    header: {
        flexDirection:'row',
        justifyContent: 'space-between',
        // borderWidth: 2,
        // borderColor: 'black',
        // borderStyle: 'solid',
        width: '100%',
        height: '0.8in'
    },
    headerBlock: {
        width: '50%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
        // borderWidth: 2,
        // borderColor: 'red',
        // borderStyle: 'solid',
    },
    title: {
        fontSize: '15pt',
        textDecoration: 'underline',
        fontWeight: 'bold',
        left: '0.25in',
        marginBottom: '.10in'
    },
    sectionDetails: {
        fontSize: '13pt',
        marginTop: '.05in',
        marginBottom: '.02in',
        width: '100%',
    },
    sectionSubDetails: {
        fontSize: '11pt',
        marginBottom: '.05in',
        left: '.2in'
    },
    layout: {
        row: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: '11pt',
            marginBottom: '.05in',
        },
        bullet: {
            height: '100%',
        }
    }
});

const ListItem = ({ children }) => {
    return (
        <View style={styles.layout.row}>
            <View style={styles.layout.bullet}>
                <Text>{'\u2022' + " "}</Text>
            </View>
            <Text style={styles.sectionSubDetails}>{children}</Text>
        </View>
    )
}

const ACCOUNTS_REPORT = ({ reportData }) => {

    let todaysDate = getTodaysDate(new Date());

    return (
        <PDFViewer style={{ width: '80%', height: '95%' }}>
            <Document title={reportData.length === 1 ? `Account Report: ${reportData[0].street_address}, ${reportData[0].city}, ${reportData[0].state} | ${todaysDate}` : `Accounts Report | ${todaysDate}`} author='Sharps Lawncare' subject='Account(s) Report'>
            {reportData.map(account => (
                <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        <View style={styles.headerBlock}>
                            <Image style={[styles.logo, {left: '.25in'}]} src={logo} ></Image>
                        </View>
                        <View style={[styles.headerBlock, {fontSize: '10pt'}]}>
                            <Text style={[{fontSize: '17pt', fontWeight: 'bold', textDecoration: 'underline'}]}>
                                {reportData.length > 1 ? 'Accounts Report' : 'Account Report'}
                            </Text>
                            <Text>
                                {todaysDate}
                            </Text>
                        </View>
                    </View>
                    <View style={{ height: '.35in', width: '100%', flexDirection: 'row', justifyContent: 'center'}}>
                        <View style={{ height: '1pt', width: '90%', borderWidth: '1.5pt', borderColor: 'black', borderStyle: 'solid' , borderRadius: '5pt', backgroundColor: 'black' }}></View>
                    </View>
                    <View style={styles.section}>   
                        <View style={[{flexDirection:'row', alignItems: 'center'}]}>
                            <Text style={styles.title}>
                                Account:
                            </Text>
                            <Text style={[{left: '.35in', fontSize: '13pt', height: '85%'}]}>
                                {account.street_address}, {account.city}, {account.state}, {account.zip_code}
                            </Text>
                        </View>
                        <View style={[{flexDirection:'row', alignItems: 'center'}]}>
                            <Text style={styles.title}>
                                Type:
                            </Text>
                            <Text style={[{left: '.35in', fontSize: '13pt', height: '85%'}]}>
                                {account.type}
                            </Text>
                        </View>
                        <View style={[{flexDirection:'row', alignItems: 'center'}]} >
                            <Text style={styles.title}>
                                Started:
                            </Text>
                            <Text style={[{left: '.35in', fontSize: '13pt', height: '85%'}]}>
                                {parseDate(account.date_created)}
                            </Text>
                        </View>
                        <View style={[{flexDirection:'row', alignItems: 'center'}]} >
                            <Text style={styles.title}>
                                Client:
                            </Text>
                            <Text style={[{left: '.35in', fontSize: '13pt', height: '85%'}]}>
                                {account.clientDetails.first_name} {account.clientDetails.last_name}
                            </Text>
                        </View>
                        {account.unpaidServices.length === 0 ? 
                        <View style={[{flexDirection:'row', alignItems: 'center'}]} >
                            <Text style={[styles.title]}>
                                Outstanding:
                            </Text>
                            <Text style={[{left: '.35in', fontSize: '13pt', height: '85%'}]}>
                                All Debts Paid
                            </Text>
                        </View> : 
                        <View>
                            <View style={[{flexDirection:'row', alignItems: 'center'}]} >
                                <Text style={styles.title}>
                                    Total Outstanding:
                                </Text>
                                <Text style={[{left: '.35in', fontSize: '13pt', height: '85%'}]}>
                                    ${account.sumOfDebts}.00
                                </Text>
                            </View>
                            <View style={{ height: '.35in', width: '100%', flexDirection: 'row', justifyContent: 'center'}}>
                                <View style={{ height: '1pt', width: '90%', borderWidth: '1.5pt', borderColor: 'black', borderStyle: 'solid' , borderRadius: '5pt', backgroundColor: 'black' }}></View>
                            </View>
                            <View >
                                <Text style={styles.title}>
                                    Unpaid Services:
                                </Text>
                                {account.unpaidServices.map(service => (
                                    <View key={service.service_id} style={[{left: '.35in'}]}>
                                        <Text style={styles.sectionDetails}>
                                            {service.service_type} | {parseDate(service.timestamp)}:
                                        </Text>
                                        <ListItem>
                                            Charged: ${service.amount_charged}
                                        </ListItem>
                                        <ListItem>
                                            Remaining: ${service.charge_outstanding}
                                        </ListItem>
                                    </View>
                                ))}
                            </View>
                        </View>
                        }
                        {account.services.length === 0 ?
                            <View style={[{flexDirection:'row', alignItems: 'center'}]} >
                                <Text style={[styles.title]}>
                                    Past Months Service:
                                </Text>
                                <Text style={[{left: '.35in', fontSize: '13pt', height: '85%'}]}>
                                    No Recent Services
                                </Text>
                            </View> 
                            :
                            <View style={[{marginBottom: '.15in'}]}>
                                <Text style={styles.title}>
                                    Past Months Service:
                                </Text>
                                {account.services.map(service => (
                                    <View key={service.service_id} style={[{left: '.35in'}]}>
                                        { service.amount_charged != 0 ?
                                        <View>
                                            <Text style={styles.sectionDetails}>
                                                {service.service_type} | {parseDate(service.timestamp)} {parseTime(service.timestamp)} |
                                            </Text>
                                            <ListItem>
                                                Charged: ${service.amount_charged}
                                            </ListItem>
                                            <ListItem>
                                                Remaining: ${service.charge_outstanding}
                                            </ListItem>
                                            {service.description === "" ? <></> : 
                                            <ListItem>
                                                Description: {service.description}
                                            </ListItem>}
                                        </View>
                                        : 
                                        <Text style={[styles.sectionDetails, {flexDirection:'row'}]}>
                                            No Recent Services
                                        </Text>
                                        }
                                    </View>
                                    
                                ))}
                            </View>
                        }
                        {account.chargebacks.length === 0 ?
                            <View style={[{flexDirection:'row', alignItems: 'center'}]} >
                                <Text style={[styles.title]}>
                                    Past Months Chargebacks:
                                </Text>
                                <Text style={[{left: '.35in', fontSize: '13pt', height: '85%'}]}>
                                    No Recent Chargebacks
                                </Text>
                            </View> 
                            :
                            <View >
                                <Text style={styles.title}>
                                    Past Months Chargebacks:
                                </Text>
                                {account.chargebacks.map(chargeback => (
                                    <View key={chargeback.chargeback_id} style={[{left: '.35in'}]}>
                                        <Text style={[styles.sectionDetails, {marginBottom: '.15in'}]}>
                                            Original Service: {chargeback.service_type} | {parseDate(chargeback.service_timestamp)} {parseTime(chargeback.service_timestamp)} | ${chargeback.service_charge}
                                        </Text>
                                        <ListItem >
                                            Chargeback Occured: {parseDate(chargeback.chargeback_timestamp)} {parseTime(chargeback.chargeback_timestamp)}
                                        </ListItem>
                                        <ListItem >
                                            Reason: {chargeback.chargeback_description}
                                        </ListItem>
                                    </View>
                                    
                                ))}
                            </View>
                        }
                    </View>
                </Page>
            ))}
            </Document>
        </PDFViewer>
    )
}

export default ACCOUNTS_REPORT;