import React, { useEffect, useState } from 'react';
import axios from '../../../../../../apiConfig';
import { useConfirmation } from '../../../../../../Contexts/Confirmation';
import logFormSubmission from '../../../../../../Utilities/Requests/databaseMessage';
import FormDeleteClientUI from './FormDeleteClientUI';

export default function FormDeleteClient({ clients, accounts, refreshBusinessData, userId, userType }) {
    const { togglePopup, setOptionalWarning, setSubmitLogic, setLabels, setConfirmationData } = useConfirmation();
    const [notes, setNotes] = useState('');
    const [formData, setFormData] = useState({
        clientId: null
    });
    const [uiMessage, setUIMessage] = useState('');
    const [client, setClient] = useState(null);
    const [clientAccounts, setClientAccounts] = useState([]);

    useEffect(() => {
        if (formData.clientId) {
            const selectedClient = clients.find(c => c.user_id === parseInt(formData.clientId));
            const relatedAccounts = accounts.filter(a => a.user_id === parseInt(formData.clientId));
            setClient(selectedClient);
            setClientAccounts(relatedAccounts);
        }
    }, [formData.clientId, clients, accounts]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setConfirmationData(formData);
        setLabels(Object.keys(formData).map(key => ({ label: key, value: formData[key] })));
        setOptionalWarning(''); //UPDATE THIS TO INCLUDE A WARNING
        let messageDetails = '';
        let messageError = '';

        setSubmitLogic(() => async () => {
            try {
                const response = await axios.post('/admin/deleteClient', formData);
                messageDetails = 'SUCCESSFUL: Client archiving for - ';
                setUIMessage(response.data.message);
                refreshBusinessData();
            } catch (error) {
                console.error('Error deleting client:', error);
                messageDetails = `FAILED: Client archiving for - `;
                messageError = JSON.stringify(error.response.data);
                setUIMessage(`${messageDetails} Client ${formData.clientId}, ${client?.first_name} ${client?.last_name} Error: ${messageError}`);
            } finally {
                const message = `${messageDetails} Client ${formData.clientId}, ${client?.first_name} ${client?.last_name} ${messageError ? `Error: ${messageError}` : ''}`;
                const log = {
                    senderId: userId,
                    accountId: null,
                    senderType: userType,
                    subject: 'CLIENT DELETION',
                    details: message,
                    notes: notes
                };
                logFormSubmission(log);
            }
        });
        togglePopup(true);
    };

    return (
        <FormDeleteClientUI
            formData={formData}
            notes={notes}
            uiMessage={uiMessage}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            setNotes={setNotes}
            clients={clients}
            client={client}
            clientAccounts={clientAccounts}
        />
    );
}
