import React from 'react';
import styles from './client_report.module.css';

const FormClientsReportUI = ({
    clients,
    selectedClientIds,
    handleClientSelect,
    handleSubmit
}) => (
    <div className={styles.form_clients_report_container}>
        <form className={styles.dropdown_form_container} onSubmit={handleSubmit}>
            <div className={styles.header_container}>
                <h3 className={styles.header}>List of Clients</h3>
            </div>
            <div className={styles.options_list}>
                <label className="title" htmlFor="dropdown_select">
                    Multi-Select: {`(Hold Ctrl + Click)`}
                </label>
                <select
                    className={`form-select ${styles.dropdown}`}
                    id="dropdown_select"
                    onChange={handleClientSelect}
                    multiple
                >
                    {clients.map((client) => (
                        <option
                            className={styles.option}
                            key={client.user_id}
                            value={client.user_id}
                        >
                            {client.first_name} {client.last_name}
                        </option>
                    ))}
                </select>
            </div>
            <div className={styles.button_container}>
                <button className={`btn ${styles.submit_button}`} type="submit">
                    Generate
                </button>
            </div>
        </form>
    </div>
);

export default FormClientsReportUI;
