import React from 'react';
import axios from '../../../../apiConfig';

import styles from './CSS/LogoutButton.module.css';

const LogoutButton = ({ setStage, setDashboard }) => {
    const handleLogout = () => {
        axios.get('/auth/logout')
            .then(() => {
                setStage('LAUNCH');
                setDashboard('DEFAULT');
            })
            .catch(error => {
                console.error('Error logging out:', error);
            });
    };

    return (
        <button className={`btn ${styles.button}`} onClick={handleLogout}>Logout</button>
    );
};

export default LogoutButton;
