import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import logo from '../../../../Images/Logo-Design-Blacksmith-Revamp.png';
import getTodaysDate from '../../../../Utilities/Functions/getTodaysDate';
import getCurrentBusinessCycle from '../../../../Utilities/Functions/getCurrentBusinessCycle';
import { parseDate, parseTime } from '../../../../Utilities/Functions/parseDateTime';

// Styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#E4E4E4',
        flexDirection: 'column',
        alignItems: 'center'
    },
    section: {
        marginTop: '.1in',
        marginBottom: '.1in',
        borderBottomWidth: 2,
        borderColor: 'black',
        borderStyle: 'solid',
        width: '90%',
    },
    logo: {
        width: '1.5in',
        height: '0.48in',
    },
    header: {
        flexDirection:'row',
        justifyContent: 'space-between',
        // borderWidth: 2,
        // borderColor: 'black',
        // borderStyle: 'solid',
        width: '100%',
        height: '0.8in'
    },
    headerBlock: {
        width: '50%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
        // borderWidth: 2,
        // borderColor: 'red',
        // borderStyle: 'solid',
    },
    title: {
        fontSize: '14pt',
        textDecoration: 'underline',
        fontWeight: 'bold',
        left: '0.25in',
        marginBottom: '.10in'
    },
    sectionDetails: {
        fontSize: '11pt',
        marginTop: '.05in',
        marginBottom: '.02in'
    },
    sectionSubDetails: {
        fontSize: '11pt',
        marginBottom: '.05in',
        left: '.2in'
    }
});


const TestDocument = ({ reportData }) => {

    let todaysDate = getTodaysDate(new Date());
    let currentBusinessCycle = getCurrentBusinessCycle();

    return (
        <PDFViewer key={new Date().getTime()} style={{ width: '100%', height: '90vh' }}>
            <Document title={`Invoice Report | ${todaysDate}`} author='Sharps Lawncare' subject='Invoice Report'>
                <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        <View style={styles.headerBlock}>
                            <Image style={[styles.logo, {left: '.25in'}]} src={logo} ></Image>
                        </View>
                        <View style={[styles.headerBlock, {fontSize: '10pt'}]}>
                            <Text style={[{fontSize: '17pt', fontWeight: 'bold', textDecoration: 'underline'}]}>
                                Invoice Report
                            </Text>
                            <Text>
                                {todaysDate}
                            </Text>
                        </View>
                    </View>
                    <View style={{ height: '.35in', width: '100%', flexDirection: 'row', justifyContent: 'center'}}>
                        <View style={{ height: '1pt', width: '90%', borderWidth: '1.5pt', borderColor: 'black', borderStyle: 'solid' , borderRadius: '5pt', backgroundColor: 'black' }}></View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.title}>
                            Invoice #{reportData.unpaidInvoiceDetails.invoice_id}:
                        </Text>
                        <Text style={styles.sectionDetails}>
                            {/* Client: {reportData.client.first_name} {reportData.client.last_name} */}
                        </Text>
                        <Text style={styles.sectionDetails}>
                            {/* Date Issued: {parseDate(reportData.unpaidInvoiceDetails.date_issued)} {parseTime(reportData.unpaidInvoiceDetails.date_issued)} */}
                        </Text>
                        <Text style={styles.sectionDetails}>
                            {/* Due Date: {parseDate(reportData.unpaidInvoiceDetails.due_date)} {parseTime(reportData.unpaidInvoiceDetails.due_date)} */}
                        </Text>
                        <Text style={styles.sectionDetails}>
                            {/* Total Charge: ${reportData.unpaidInvoiceDetails.amount_charged} */}
                        </Text>
                        <Text style={styles.sectionDetails}>
                            {/* Charge Remaining: ${reportData.unpaidInvoiceDetails.charge_outstanding} */}
                        </Text>
                    </View>
                        <Text style={styles.title}>
                            List of Account Services:
                        </Text>
                    
                </Page>
            </Document>
        </PDFViewer>
    )
};

export default TestDocument;