import React, { useState } from 'react';
import styles from './BUSINESS_EXAMPLES.module.css';

const BUSINESS_EXAMPLES = () => {

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                
            </div>
        </div>
    )
}

export default BUSINESS_EXAMPLES;