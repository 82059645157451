import React from 'react';
import styles from './invoice_report.module.css';

const RadioBox = ({ selectedRadio, handleRadioSelect }) => (
    <div className={styles.radiobox_container}>
        <div className={styles.header_container}>
            <h3 className={styles.header}>Sort By:</h3>
        </div>
        <div className={`form-check form-check-inline ${styles.input_container}`}>
            <input
                className={`form-check-input ${styles.radio_input}`}
                type="radio"
                name="all"
                id="flexRadioDefault1"
                onChange={handleRadioSelect}
                value="all"
                checked={selectedRadio === 'all'}
            />
            <label className={`form-check-label ${styles.label}`} htmlFor="flexRadioDefault1">
                All
            </label>
        </div>
        <div className={`form-check form-check-inline ${styles.input_container}`}>
            <input
                className={`form-check-input ${styles.radio_input}`}
                type="radio"
                name="incycle"
                id="flexRadioDefault2"
                onChange={handleRadioSelect}
                value="incycle"
                checked={selectedRadio === 'incycle'}
            />
            <label className={`form-check-label ${styles.label}`} htmlFor="flexRadioDefault2">
                In-Cycle
            </label>
        </div>
        <div className={`form-check form-check-inline ${styles.input_container}`}>
            <input
                className={`form-check-input ${styles.radio_input}`}
                type="radio"
                name="overdue"
                id="flexRadioDefault3"
                onChange={handleRadioSelect}
                value="overdue"
                checked={selectedRadio === 'overdue'}
            />
            <label className={`form-check-label ${styles.label}`} htmlFor="flexRadioDefault3">
                Overdue
            </label>
        </div>
    </div>
);

const FormInvoiceReportUI = ({
    selectedInvoiceOption,
    selectedInvoiceID,
    handleInvoiceSelect,
    handleSubmit,
    selectedRadio,
    handleRadioSelect
}) => (
    <div className={styles.form_invoice_report_container}>
        <RadioBox selectedRadio={selectedRadio} handleRadioSelect={handleRadioSelect} />
        <form className={styles.dropdown_form_container} onSubmit={handleSubmit}>
            <div className={styles.options_list}>
                <label className={styles.label} htmlFor="dropdown_select">
                    Select an Invoice
                </label>
                <select
                    className={`form-select ${styles.dropdown}`}
                    id="dropdown_select"
                    onChange={handleInvoiceSelect}
                    value={selectedInvoiceID}
                >
                    <option className={styles.option} key={-1} value={null}>
                        List of Invoices
                    </option>
                    {selectedInvoiceOption.map((invoice) => (
                        <option className={styles.option} key={invoice.id} value={invoice.id}>
                            {invoice.first_name} {invoice.last_name} | {invoice.date_issued}
                        </option>
                    ))}
                </select>
            </div>
            <div className={styles.button_container}>
                <button className={`btn ${styles.submit_button}`} type="submit">
                    Generate
                </button>
            </div>
        </form>
    </div>
);


export default FormInvoiceReportUI;
