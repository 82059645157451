import React, { createContext, useContext, useState, useEffect } from 'react';

export const ConfirmationContext = createContext();

export const useConfirmation = () => {
    const context = useContext(ConfirmationContext);
    if (!context) {
        throw new Error('useConfirmation must be used within a ConfirmationProvider');
    }
    return context;
}


export const ConfirmationProvider = ({ children }) => {

    const [popup, togglePopup] = useState(false);
    const [labels, setLabels] = useState([]);
    const [confirmationData, setConfirmationData] = useState({});
    const [optionalWarning, setOptionalWarning] = useState('WARNING - Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, accusamus quaerat. Iure numquam iusto est doloremque enim rerum aperiam voluptatem cupiditate quisquam amet perferendis, hic doloribus. Expedita debitis, perspiciatis itaque blanditiis veniam, repellat quas animi maxime, voluptatem officia molestiae iure vero doloremque facere laborum aperiam cumque fugiat totam deserunt. Beatae.' );
    const [submitLogic, setSubmitLogic] = useState(() => {});

    const confirm = (logic, data) => {
        logic(data);
    }

    return (
        <ConfirmationContext.Provider value={{ confirm, popup, togglePopup, labels, setLabels, confirmationData, setConfirmationData, optionalWarning, setOptionalWarning, submitLogic, setSubmitLogic }}>
            {children}
        </ConfirmationContext.Provider>
    );
}