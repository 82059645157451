import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';
import logo from '../../../../../../Images/Logo-Design-Blacksmith-Revamp.png';
import getTodaysDate from '../../../../../../Utilities/Functions/getTodaysDate';
import getCurrentBusinessCycle from '../../../../../../Utilities/Functions/getCurrentBusinessCycle';
import { parseDate } from '../../../../../../Utilities/Functions/parseDateTime';



// Fonts
// Font.register({ family: 'Abel', src: 'http://fonts.gstatic.com/s/abel/v6/N59kklKPso9WzbZH9jwJSg.ttf'}) Error with font format, potentially because I have a local TLS cert

// Styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#E4E4E4',
        flexDirection: 'column',
        alignItems: 'center'
    },
    section: {
        marginTop: '.1in',
        marginBottom: '.1in',
        borderBottomWidth: 2,
        borderColor: 'black',
        borderStyle: 'solid',
        width: '90%',
    },
    logo: {
        width: '1.5in',
        height: '0.48in',
    },
    header: {
        flexDirection:'row',
        justifyContent: 'space-between',
        // borderWidth: 2,
        // borderColor: 'black',
        // borderStyle: 'solid',
        width: '100%',
        height: '0.8in'
    },
    headerBlock: {
        width: '50%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
        // borderWidth: 2,
        // borderColor: 'red',
        // borderStyle: 'solid',
    },
    title: {
        fontSize: '14pt',
        textDecoration: 'underline',
        fontWeight: 'bold',
        left: '0.25in',
        marginBottom: '.10in'
    },
    sectionDetails: {
        fontSize: '11pt',
        marginTop: '.05in',
        marginBottom: '.02in'
    },
    sectionSubDetails: {
        fontSize: '11pt',
        marginBottom: '.05in',
        left: '.2in'
    }
});

const Report_Timeframe = ({ reportData }) => {
    let currentBusinessCycle = getCurrentBusinessCycle();
    switch (reportData.dateSelection) {
        case 'current':
            return (
                <Text>
                    {currentBusinessCycle}
                </Text>);
        case 'selected':
            return (
                <Text>
                    {reportData.startDate} — {reportData.endDate}
                </Text>
            );
        case 'timeframe':
            return (
                <Text>
                    {reportData.startDate} — {reportData.endDate}
                </Text>
            );
        default: return (
            <Text>
                {currentBusinessCycle}
            </Text>);
    }
}

const BUSINESS_REPORT = ({ reportData }) => {

    let todaysDate = getTodaysDate(new Date());

    return (
        <PDFViewer key={new Date().getTime()} style={{ width: '80%', height: '95%' }}>
            <Document title={`Business Report | ${todaysDate}`} author='Sharps Lawncare' subject='Business Report'>
                <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        <View style={styles.headerBlock}>
                            <Image style={[styles.logo, {left: '.25in'}]} src={logo} ></Image>
                        </View>
                        <View style={[styles.headerBlock, {fontSize: '10pt'}]}>
                            <Text style={[{fontSize: '17pt', fontWeight: 'bold', textDecoration: 'underline'}]}>
                                Business Report
                            </Text>
                            <Text>
                                <Report_Timeframe reportData={reportData} />
                            </Text>
                        </View>
                    </View>
                    <View style={{ height: '.35in', width: '100%', flexDirection: 'row', justifyContent: 'center'}}>
                        <View style={{ height: '1pt', width: '90%', borderWidth: '1.5pt', borderColor: 'black', borderStyle: 'solid' , borderRadius: '5pt', backgroundColor: 'black' }}></View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.title}>
                            Payment Details:
                        </Text>
                        <Text style={styles.sectionDetails}>
                            Invoices Outstanding: ${reportData.totalOutstanding}
                        </Text>
                        <Text style={styles.sectionDetails}>
                            Invoices Paid: ${reportData.totalPaid}
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.title}>
                            Services:
                        </Text>
                        <Text style={styles.sectionDetails}>
                            {reportData.dateSelection === 'timeframe' ? <></> : `Count of Standard Weekly Services: ${reportData.numberOfExpectedServices[0]}`}
                        </Text>
                        {reportData.numberOfServicesCompleted.length === 0 ? 
                        <View>
                            <Text style={styles.sectionDetails}>Completed Services: 0</Text>
                        </View> 
                        
                        : <View>
                            {reportData.numberOfServicesCompleted.map(serviceType => (
                            <Text style={styles.sectionSubDetails}>
                                Service Type: {serviceType.service_type} | Services Completed: {serviceType.total_completed}
                            </Text>

                            ))}
                        </View>}
                        <Text style={styles.sectionDetails}>
                            Sum of Charges: {reportData.sumOfServiceCharges === null ? 'No Services Completed' : `\$${reportData.sumOfServiceCharges}`}
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.title}>
                            List of New Clients:
                        </Text>
                        <View>
                            {reportData.newClients.length != 0 ? 
                            reportData.newClients.map(client => (
                                <Text style={styles.sectionDetails}>
                                    Client: {client.first_name} {client.last_name} | Email: {client.email}
                                </Text >)
                            )
                            : <Text style={styles.sectionDetails}>— N/A —</Text>
                            }
                        </View>
                    </View>
                    <View style={[styles.section, {borderBottom: 'none'}]}>
                        <Text style={styles.title}>
                            New Accounts:
                        </Text>
                        <View>
                            {reportData.newClients.length != 0 ? reportData.newAccounts.map(account => (
                            <View>
                                <Text style={styles.sectionDetails}>
                                    Account: {account.street_address}, {account.city}, {account.state}
                                </Text>
                                <Text style={styles.sectionSubDetails}>
                                    Type: {account.type} | Price: {account.price} | Client: {account.first_name} {account.last_name}
                                </Text>
                            </View>
                            ))
                        : <Text style={styles.sectionDetails}>— N/A —</Text>
                        }
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
}

export default BUSINESS_REPORT;