import React, { useState } from 'react';

import { useStage } from '../../../Contexts/StageContext';
import { useDashboard } from '../../../Contexts/DashboardContext';

import DropdownMenu from './Modules/DropdownMenu';
import DashboardTitle from './Modules/DashboardTitle';
import LogoutButton from './Modules/LogoutButton';
import './ADMIN_HEADER.css';

const ADMIN_HEADER = () => {
    const [dropdownActive, setDropdownActive] = useState(false);
    const { stage, setStage } = useStage();
    const { dashboard, setDashboard } = useDashboard();
    const [componentName, setComponentName] = useState('Service Management');

    const toggleDropdown = () => setDropdownActive(!dropdownActive);

    return (
        <div className='header_container'>
            <div className='header_content_container'>
                <div className='dropdown_and_component_name_container'>
                    <DropdownMenu
                        isActive={dropdownActive}
                        toggleDropdown={toggleDropdown}
                        setDashboard={setDashboard}
                        setComponentName={setComponentName}
                    />
                    <DashboardTitle title={componentName} />
                </div>
                <LogoutButton setStage={setStage} setDashboard={setDashboard} />
            </div>
        </div>
    );
};

export default ADMIN_HEADER;
