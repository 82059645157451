import React, { useEffect, useState } from "react";

import ADMIN_HEADER from './Header_Components/ADMIN_HEADER';
import SERVICE_MGMT from "./Dashboard_Components/Service_Management/SERVICE_MGMT";
import CLIENT_ACCOUNT_MGMT from './Dashboard_Components/Client_Account_Management/CLIENT_ACCOUNT_MGMT';
import NOTIFICATIONS from './Dashboard_Components/Notifications/NOTIFICATIONS';
import REPORT_GENERATION from "./Dashboard_Components/Report_Generation/REPORT_GENERATION";
import AdminBody from "./AdminBody";

import { useDashboard } from '../../Contexts/DashboardContext';
import { useServiceData } from '../../Contexts/ServiceDataContext';
import { useConfirmation } from "../../Contexts/Confirmation";

import styles from './ADMIN_DASHBOARD.module.css';


//admin main view
const ADMIN_DASHBOARD = () => {

    const { dashboard, setDashboard } = useDashboard();
    const { refreshBusinessData } = useServiceData();
    const { popup } = useConfirmation();

    useEffect(() => {
        setDashboard('SERVICE_MGMT');
        refreshBusinessData();
    }, []);

    const renderContent = () => {
        switch(dashboard) {
            case 'SERVICE_MGMT':
                return (
                        <SERVICE_MGMT />
                );
            case 'CLIENT_ACCOUNT_MGMT':
                return (
                        <CLIENT_ACCOUNT_MGMT />
                );
            case 'NOTIF':
                return (
                        <NOTIFICATIONS />
            );
            case 'REPORT':
                return (
                        <REPORT_GENERATION />
            );
            default:
                return null;
        }
    }

    return (
        <div className={styles.dashboard_container}>
            <ADMIN_HEADER />
            <AdminBody>
                {renderContent()}
            </AdminBody>
        </div>
    )
}

export default ADMIN_DASHBOARD;