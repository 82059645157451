import React, { useEffect } from 'react';
import { useConfirmation } from '../../Contexts/Confirmation';

import styles from './CSS/confirmation.module.css';

const Header = ({ togglePopup }) => (
    <div className={styles.header_container}>
        <div className={styles.exit_icon_container}>
            <i className={`bi bi-x-lg ${styles.exit_icon}`} onClick={() => togglePopup(false)}></i>
        </div>
    </div>
)


const Content = ({ optionalWarning, confirmationData, labels }) => (
    <div className={styles.content_container}>
        {optionalWarning && (
            <div className={styles.warning_message_container}>
                <p className={styles.warning_message}>{optionalWarning}</p>
            </div>
            )}
        {confirmationData && labels.map(({label, value}) =>  (
            <div key={label} className={styles.confirmationData_container}>
                <p className={styles.confirmationData}>{label}: {value}</p>
            </div>
            ))}
    </div>
)


const Footer = ({ submitLogic, togglePopup }) =>  (
    <div className={styles.footer_container}>
        <div className={styles.footer}>
            <button className={`btn ${styles.confirm_button}`} onClick={() => {submitLogic(); togglePopup(false);}}>Confirm</button>
        </div>
    </div>
)


const ConfirmationModal = () => {

    const { togglePopup, confirmationData, optionalWarning, submitLogic, labels } = useConfirmation();

    useEffect(() => {
        console.log('confirmationData: ', confirmationData);
    }, [confirmationData])

    return (
        <div className={styles.outer_container}>
            <div className={styles.backdrop}>
                <div className={styles.confirmation_container}>
                    <Header togglePopup={togglePopup} />
                    <Content optionalWarning={optionalWarning} confirmationData={confirmationData} labels={labels} />
                    <Footer togglePopup={togglePopup} submitLogic={submitLogic} />
                </div>
            </div>
        </div>
    )
}

ConfirmationModal.defaultProps = {
    optionalWarning: ''
}

export default ConfirmationModal;