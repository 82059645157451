import React, { useState } from 'react';
import axios from '../../../../../../apiConfig';
import logFormSubmission from '../../../../../../Utilities/Requests/databaseMessage';
import { useConfirmation } from '../../../../../../Contexts/Confirmation';
import FormCreateClientWithAccountUI from './FormCreateClientWithAccountUI';

const FormCreateClientWithAccount = ({ userId, userType, refreshBusinessData }) => {
    const defaultForm = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        type: 'residential',
        streetAddress: '',
        zipCode: '',
        city: '',
        county: '',
        state: 'KY',
        price: '',
        serviceFrequency: '1',
        active: '1'
    };

    const [notes, setNotes] = useState('');
    const [formData, setFormData] = useState(defaultForm);
    const [userInterfaceMessage, setUserInterfaceMessage] = useState('');
    const [toggleUserInterfaceMessageClass, setToggleUserInterfaceMessageClass] = useState('');

    const { togglePopup, setConfirmationData, setSubmitLogic, setLabels } = useConfirmation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const clearForm = () => {
        setFormData(defaultForm);
        setNotes('');
        setUserInterfaceMessage('');
        setToggleUserInterfaceMessageClass('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setConfirmationData({ ...formData });
        setLabels(Object.keys(formData).map(key => ({ label: key, value: formData[key] })));
        setSubmitLogic(() => async () => {
            let messageDetails = '';
            let messageError = '';
            let accountId;

            try {
                const response = await axios.post('/admin/createClientWithAccount', formData);
                accountId = response.data.accountId;
                messageDetails = 'SUCCESSFUL: Client with account creation for - ';
                setToggleUserInterfaceMessageClass('success');
                setUserInterfaceMessage(response.data.message);
                refreshBusinessData();
            } catch (error) {
                console.error('Error creating client or account:', error);
                if (error.response.status && error.response.status === 400) {
                    console.error("Error creating client:", error.response.data.errors[0].msg);
                    messageDetails = "FORMATTING ERROR: Client creation for - ";
                    messageError = error.response.data.errors[0].msg;
                } else {
                    messageDetails = `FAILED: Client with account creation for - `;
                    messageError = JSON.stringify(error.response.data);
                }
                setToggleUserInterfaceMessageClass('failure');
                setUserInterfaceMessage(`${messageDetails} ${formData.firstName} ${formData.lastName} at ${formData.streetAddress}, ${formData.city}. Error: ${messageError}`);
            } finally {
                const message = `${messageDetails} ${formData.firstName} ${formData.lastName} at ${formData.streetAddress}, ${formData.city} ${messageError}`;
                const log = {
                    senderId: userId,
                    accountId: accountId,
                    senderType: userType,
                    subject: 'CLIENT WITH ACCOUNT CREATION',
                    details: message,
                    notes: notes
                };
                logFormSubmission(log);
            }
        });
        togglePopup(true);
    };

    return (
        <FormCreateClientWithAccountUI
            formData={formData}
            notes={notes}
            userInterfaceMessage={userInterfaceMessage}
            toggleUserInterfaceMessageClass={toggleUserInterfaceMessageClass}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            clearForm={clearForm}
            setNotes={setNotes}
        />
    );
};

export default FormCreateClientWithAccount;
