import React, { createContext, useContext, useState, useEffect } from 'react';

import axios from '../apiConfig';

const ComponentContext = createContext();


export const useComponent = () => {
    const context =  useContext(ComponentContext);
    if (!context) {
        throw new Error('useComponent must be used within a ComponentProvider');
    }
    return context;
}


// Handles what component is displayed within the dashboard.
export const ComponentProvider = ({ children }) => {

    const [component, setComponent] = useState('');

    const value = {
        component,
        setComponent,
    };

    return (
        <ComponentContext.Provider value={value}>
            {children}
        </ComponentContext.Provider>
    );    
}
