import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../apiConfig';

const UserContext = createContext();

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
}


export const  UserProvider = ({ children }) => {
    const [userType, setUserType] = useState('');
    const [userId, setUserId] = useState(0);

    useEffect(() => {

        const verifyToken = async () => {
            try {
                const response = await axios.get('/auth/verifyToken');
                if (response.data) {
                    setUserType(response.data.userType);
                    setUserId(response.data.userId);
                }
            } catch (error) {
                console.error("Error verifying token:", error);

            }
        };

        verifyToken();
    }, []);

    useEffect(() => {
        const testRailway = async () => {
            try {
                const response = await axios.get('/auth/testRailway');
                console.log("Testing Railway:", response.data);
            } catch (error) {
                console.error("Error testing Railway:", error);
            }
        };

        testRailway();
    }, [])

    const value = {
        userType,
        setUserType,
        userId,
        setUserId
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
}