import React, { useState } from 'react';
import axios from '../../../../../../../apiConfig';
import FormBusinessReportUI from './FormBusinessReportUI';
import getTodaysDate from '../../../../../../../Utilities/Functions/getTodaysDate';
import styles from './business_report.module.css';

const FormBusinessReport = ({ userId, userType, setPdfComponent, setReportData }) => {
    const [dateSelectionType, setDateSelectionType] = useState('current'); // 'current', 'selected', 'timeframe'
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [formMaxDate, setFormMaxDate] = useState(new Date());
    const [uiMessage, setUIMessage] = useState('');

    const handleDateSelectionChange = (type) => {
        setDateSelectionType(type);
        setUIMessage('');
        setStartDate(new Date());
        setEndDate(new Date());
    };

    const handleSelectedDateChange = (e) => setSelectedDate(e.target.value);

    const handleStartDateChange = (e) => setStartDate(e.target.value);

    const handleEndDateChange = (e) => setEndDate(e.target.value);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/admin/generateBusinessReport', { dateSelectionType, selectedDate, startDate, endDate });
            await setReportData(response.data);
            setPdfComponent('BUSINESS_PDF');
            console.log('Reponse from test: ', response.data);
        } catch (error) {
            console.log('Error in test controller: ', error);
            setUIMessage(error.response.data.errors.map(err => <div className={styles.ui_message_container}><b>WARNING: {err.msg}</b></div>));
        }
    };

    return (
        <FormBusinessReportUI
            dateSelectionType={dateSelectionType}
            selectedDate={selectedDate}
            startDate={startDate}
            endDate={endDate}
            formMaxDate={formMaxDate}
            handleDateSelectionChange={handleDateSelectionChange}
            handleSelectedDateChange={handleSelectedDateChange}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            handleSubmit={handleSubmit}
            uiMessage={uiMessage}
        />
    );
};

export default FormBusinessReport;
