import React, { createContext, useState, useContext } from 'react';

export const AccountDataContext = createContext();

export const useAccountData = () => useContext(AccountDataContext);

//handles the accounts related to the individual client. Needs to merge with ClientDataContext
export const AccountDataProvider = ({ children }) => {
    const [selectedAccount, setSelectedAccount] = useState(null);

    return (
        <AccountDataContext.Provider value={{ selectedAccount, setSelectedAccount }}>
            {children}
        </AccountDataContext.Provider>
    );
};