import React, { useState } from 'react';
import axios from '../../../../../../apiConfig';
import logFormSubmission from '../../../../../../Utilities/Requests/databaseMessage';
import { useConfirmation } from '../../../../../../Contexts/Confirmation';
import FormCreateClientUI from './FormCreateClientUI';

const FormCreateClient = ({ userId, userType, refreshBusinessData }) => {
    const [notes, setNotes] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: ''
    });

    const [userInterfaceMessage, setUserInterfaceMessage] = useState('');
    const [toggleUserInterfaceMessageClass, setToggleUserInterfaceMessageClass] = useState('');

    const { togglePopup, setConfirmationData, setSubmitLogic, setLabels } = useConfirmation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setUserInterfaceMessage('');
    };

    const handleReset = () => {
        setFormData({
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: ''
        });
        setNotes('');
        setUserInterfaceMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setConfirmationData({ ...formData });
        setLabels(Object.keys(formData).map(key => ({ label: key, value: formData[key] })));
        setSubmitLogic(() => async () => {
            let messageDetails = '';
            let messageError = '';
            setUserInterfaceMessage('');

            try {
                const response = await axios.post('/admin/createClient', formData);
                setToggleUserInterfaceMessageClass('success');
                setUserInterfaceMessage(response.data.message);
                refreshBusinessData();
                messageDetails = "SUCCESSFUL: Client creation for - ";
            } catch (error) {
                if (error.response.status === 400) {
                    messageDetails = "FORMATTING ERROR: Client creation for - ";
                    messageError = error.response.data.errors[0].msg;
                } else {
                    messageDetails = "FAILED: Client creation for - ";
                    const errorMsg = JSON.stringify(error.response.data);
                    if (errorMsg.includes("Duplicate entry") && errorMsg.includes("clients.email")) {
                        messageError = `This email already exists in the database. A unique email is required.`;
                    } else if (errorMsg.includes("Duplicate entry") && errorMsg.includes("clients.phone_number")) {
                        messageError = `This phone number already exists in the database. A unique phone number is required.`;
                    } else {
                        messageError = "Unknown error. Please contact Lachlan Sharp for review.";
                    }
                }
                setToggleUserInterfaceMessageClass('error');
                setUserInterfaceMessage(messageError);
            }

            const message = messageDetails + `${formData.firstName} ${formData.lastName} ` + messageError;
            const log = {
                senderId: userId,
                accountId: null,
                senderType: userType,
                subject: 'CLIENT CREATION',
                details: message,
                notes: notes
            };
            logFormSubmission(log);
        });
        togglePopup(true);
    };

    return (
        <FormCreateClientUI
            formData={formData}
            notes={notes}
            userInterfaceMessage={userInterfaceMessage}
            userInterfaceMessageClass={toggleUserInterfaceMessageClass}
            handleChange={handleChange}
            handleReset={handleReset}
            handleSubmit={handleSubmit}
            setNotes={setNotes}
        />
    );
};

export default FormCreateClient;
