import React, { useState, useEffect } from 'react';
import axios from '../../../../../../apiConfig';
import logFormSubmission from '../../../../../../Utilities/Requests/databaseMessage';
import FormCreateAccountUI from './FormCreateAccountUI';

import { useConfirmation } from '../../../../../../Contexts/Confirmation';

export default function FormCreateAccount({ clients, refreshBusinessData, userId, userType }) {
    const defaultForm = {
        clientId: '',
        type: 'Residential',
        streetAddress: '',
        zipCode: '',
        city: '',
        county: '',
        state: 'KY',
        price: '',
        active: '1',
        serviceFrequency: '1'
    }

    const [notes, setNotes] = useState('');
    const [formData, setFormData] = useState(defaultForm);
    const [userInterfaceMessage, setUserInterfaceMessage] = useState('');
    const [toggleUserInterfaceMessageClass, setToggleUserInterfaceMessageClass] = useState('');


    const { togglePopup, setConfirmationData, setSubmitLogic, setLabels } = useConfirmation();

    useEffect(() => {
        if (clients.length > 0) {
            setFormData({...formData, clientId: clients[0].user_id.toString() });
        }
    }, [clients]);

    const handleReset = () => {
        setFormData(defaultForm);
        setNotes('');
        setUserInterfaceMessage('');

    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setConfirmationData(formData);
        setLabels(Object.keys(formData).map(key => ({ label: key, value: formData[key] })));
        setSubmitLogic(() => async () => {
            let messageDetails = '';
            let messageError = '';
            const client = clients.find(c => c.user_id.toString() === formData.clientId);
            let accountId;

            try {
                const response = await axios.post('/admin/createAccount', formData);
                accountId = response.data.accountId;
                messageDetails = `SUCCESSFUL - Account creation for - `;
                setToggleUserInterfaceMessageClass('success');
                setUserInterfaceMessage(response.data.message);
                refreshBusinessData();

            } catch (error) {
                accountId = null;

                if (error.response.status === 400) {
                    messageDetails = "FORMATTING ERROR: Account creation for - ";
                    messageError = error.response.data.errors[0].msg;

                } else {
                    messageDetails = 'FAILED: Account creation for - ';
                    const errorMsg = JSON.stringify(error.response.data);

                    if (errorMsg.includes("Duplicate entry") && errorMsg.includes("accounts.PRIMARY")) {
                        messageError = `The address for ${formData.streetAddress}, ${formData.city} already exists in the database.`

                    } else {
                        messageError = "Unknown error. Please contact Lachlan Sharp for review.";

                    }
                }

                setToggleUserInterfaceMessageClass('error');
                setUserInterfaceMessage(messageError);
            } finally {
                const message = messageDetails + `${client.first_name} ${client.last_name}, Street Address: ${formData.streetAddress}, City: ${formData.city}, Type: ${formData.type}. ` + messageError;
                const log = {
                    senderId: userId,
                    senderType: userType,
                    accountId: accountId,
                    subject: 'ACCOUNT CREATION',
                    details: message,
                    notes: notes
                };

                logFormSubmission(log);
            }
        });
        togglePopup(true);
    }

    return (
        <FormCreateAccountUI 
            handleReset={handleReset}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            userInterfaceMessage={userInterfaceMessage}
            formData={formData}
            clients={clients}
            notes={notes}
            setNotes={setNotes}
        />
    )

}