import React, { useState, useEffect } from 'react';
import axios from '../../../../../../apiConfig';
import FormModifyClientPresentation from './FormModifyClientPresentation';
import logFormSubmission from '../../../../../../Utilities/Requests/databaseMessage';
import { useConfirmation } from '../../../../../../Contexts/Confirmation';

const FormModifyClientContainer = ({ clients, userId, userType, refreshBusinessData, fetchClients }) => {
    const { togglePopup, setConfirmationData, setOptionalWarning, setSubmitLogic, setLabels } = useConfirmation();
    const [notes, setNotes] = useState('');
    const [clientData, setClientData] = useState({
        clientId: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: ''
    });
    const [initialClientData, setInitialClientData] = useState({});
    const [displayMessage, setDisplayMessage] = useState('');

    const customLabels = {
        firstName: 'First Name',
        lastName: 'Last Name',
        phoneNumber: 'Phone Number',
        email: 'Email',
    };

    const formattedData = Object.entries(clientData).map(([key, value]) => ({
        label: customLabels[key],
        value,
    }));

    const modsToClient = Object.keys(clientData).reduce((acc, key) => {
        if (clientData[key] !== initialClientData[key]) {
            acc[key] = { from: initialClientData[key], to: clientData[key] };
        }
        return acc;
    }, {});

    const handleSelect = (e) => {
        const id = e.target.value;
        const client = clients.find(c => c.user_id === parseInt(id)) || {};
        const info = {
            clientId: id,
            firstName: client.first_name || '',
            lastName: client.last_name || '',
            phoneNumber: client.phone_number || '',
            email: client.email || ''
        };

        setClientData(info);
        setInitialClientData(info);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({ ...clientData, [name]: value });
        setDisplayMessage('');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisplayMessage('');
        setConfirmationData(clientData);
        setLabels(Object.keys(clientData).map(key => ({ label: key, value: clientData[key] })));

        setSubmitLogic(() => async () => {
            let messageDetails = '';
            let messageError = '';

            try {
                const result = await axios.post('/admin/updateClient', { clientData });

                messageDetails = `SUCCESSFUL: Client modification for - `;
                setDisplayMessage(result.data.message);
                refreshBusinessData();

            } catch (error) {
                if (error.response.status === 400) {
                    messageDetails = `FORMATTING ERROR: Client modification for - `;
                    messageError = error.response.data.errors[0].msg;

                } else {
                    messageDetails = 'FAILED: Client modification for - ';
                    messageError = error.response.data;

                }

                setDisplayMessage(error.response.data);
            } finally {
                const message = messageDetails + `${clientData.firstName} ${clientData.lastName}. Modifications: ${modsToClient}. ` + messageError;
                const log = {
                    senderId: userId,
                    accountId: null,
                    senderType: userType,
                    subject: 'CLIENT MODIFICATION',
                    details: message,
                    notes: notes
                }
                logFormSubmission(log);
            }
        });
        togglePopup(true);
    };

    return (
        <FormModifyClientPresentation
            clients={clients}
            notes={notes}
            clientData={clientData}
            setConfirmationData={setConfirmationData}
            setClientData={setClientData}
            setDisplayMessage={setDisplayMessage}
            setNotes={setNotes}
            togglePopup={togglePopup}
            formattedData={formattedData}
            handleSelect={handleSelect}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    );
}

export default FormModifyClientContainer;
