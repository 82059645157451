import React, { useState } from 'react';
import axios from '../../../../../../../apiConfig';
import FormClientsReportUI from './FormClientsReportUI';

const FormClientsReport = ({ clients, userId, userType, setPdfComponent, setReportData }) => {
    const [selectedClientIds, setSelectedClientIds] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const result = await axios.post('/admin/generateClientsReport', { selectedClientIds });
            setReportData(result.data);
            setPdfComponent('CLIENTS_PDF');
            console.log('Results: ', result.data);
            console.log('Accounts: ', result.data[0].accounts);
        } catch (error) {
            console.log('Error in frontend trycatch for /admin/generateClientsReport');
        }
    };

    const handleClientSelect = (e) => {
        const selectedOptions = e.target.selectedOptions;
        const ids = Array.from(selectedOptions).map((option) => option.value);
        setSelectedClientIds(ids);
    };

    return (
        <FormClientsReportUI
            clients={clients}
            selectedClientIds={selectedClientIds}
            handleClientSelect={handleClientSelect}
            handleSubmit={handleSubmit}
        />
    );
};

export default FormClientsReport;
