import React, { useState, useEffect } from 'react';
import axios from '../../../../../../apiConfig';
import logFormSubmission from '../../../../../../Utilities/Requests/databaseMessage';
import { useConfirmation } from '../../../../../../Contexts/Confirmation';
import FormModifyAccountUI from './FormModifyAccountUI';

const FormModifyAccount = ({ accounts, refreshBusinessData, userId, userType }) => {
    const [activeFilter, setActiveFilter] = useState('1');
    const [selectedAccountId, setSelectedAccountId] = useState('');
    const selectedAccount = accounts.find(a => a.account_id === parseInt(selectedAccountId)) || {};
    const [notes, setNotes] = useState('');
    const [accountData, setAccountData] = useState({
        type: '',
        streetAddress: '',
        zipCode: '',
        city: '',
        county: '',
        state: '',
        price: '',
        active: '',
        serviceFrequency: '',
        nickname: ''
    });
    const [initialAccountData, setInitialAccountData] = useState({});

    const [userInterfaceMessage, setUserInterfaceMessage] = useState('');
    const [toggleUserInterfaceMessageClass, setToggleUserInterfaceMessageClass] = useState('');

    const { togglePopup, setConfirmationData, setSubmitLogic, setLabels } = useConfirmation();

    const userInterfaceMessageClass = `user_interface_message_container ${toggleUserInterfaceMessageClass}`;

    const filteredAccounts = accounts.filter(account => account.active.toString() === activeFilter);

    useEffect(() => {
        if (selectedAccountId) {
            const accountInfo = {
                type: selectedAccount.type || '',
                streetAddress: selectedAccount.street_address || '',
                zipCode: selectedAccount.zip_code || '',
                city: selectedAccount.city || '',
                county: selectedAccount.county || '',
                state: selectedAccount.state || '',
                price: selectedAccount.price || '',
                active: selectedAccount.active || '',
                serviceFrequency: selectedAccount.service_frequency || '',
                nickname: selectedAccount.nickname || ''
            };
            setAccountData(accountInfo);
            setInitialAccountData(accountInfo);
        }
    }, [selectedAccountId, accounts]);

    const parseModifications = (modifications) => {
        const description = Object.entries(modifications).map(([key, { from, to }]) => {
            const readableKey = key.replace(/([A-Z])/g, ' $1').toLowerCase();
            return `${readableKey}: ${from} -> ${to}`;
        });

        return description.join(', ');
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAccountData({ ...accountData, [name]: value });
        setUserInterfaceMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let messageDetails = '';
        let messageError = '';
        setUserInterfaceMessage('');

        const modsToAccount = Object.keys(accountData).reduce((acc, key) => {
            if (accountData[key] !== initialAccountData[key]) {
                acc[key] = { from: initialAccountData[key], to: accountData[key] };
            }
            return acc;
        }, {});

        if (Object.keys(modsToAccount).length === 0) {
            return setUserInterfaceMessage('No changes have been made.');
        }

        setConfirmationData({ ...accountData });
        setLabels(Object.keys(accountData).map(key => ({ label: key, value: accountData[key] })));
        setSubmitLogic(() => async () => {
            try {
                const response = await axios.post('/admin/updateAccount', {
                    accountId: selectedAccountId,
                    ...accountData
                });

                messageDetails = `SUCCESSFUL: Account modification for - `;
                setToggleUserInterfaceMessageClass('success');
                setUserInterfaceMessage(response.data.message);
                refreshBusinessData();
            } catch (error) {
                console.error('Error updating account:', error);
                if (error.response.status === 400) {
                    messageDetails = `FORMATTING ERROR: Account modification for - `;
                    messageError = error.response.data.errors[0].msg;
                } else {
                    messageDetails = `FAILED: Account modification for - `;
                    messageError = error.response.data;
                }
                setToggleUserInterfaceMessageClass('error');
                setUserInterfaceMessage(messageError);
            } finally {
                const message = messageDetails + `${accountData.streetAddress}, ${accountData.city}, ${accountData.state}. Modifications: ${parseModifications(modsToAccount)}. ` + messageError;
                const log = {
                    senderId: userId,
                    accountId: parseInt(selectedAccountId),
                    senderType: userType,
                    subject: 'ACCOUNT MODIFICATION',
                    details: message,
                    notes: notes
                }
                logFormSubmission(log);
            }
        });
        togglePopup(true);
    };

    return (
        <FormModifyAccountUI
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            selectedAccountId={selectedAccountId}
            setSelectedAccountId={setSelectedAccountId}
            filteredAccounts={filteredAccounts}
            accountData={accountData}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            userInterfaceMessage={userInterfaceMessage}
            userInterfaceMessageClass={userInterfaceMessageClass}
            notes={notes}
            setNotes={setNotes}
        />
    );
};

export default FormModifyAccount;
