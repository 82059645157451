import axios from '../../apiConfig';

const logFormSubmission = async ({ senderId = null, accountId = null, senderType = 'server', subject, details, notes = '' }) => {
  try {
    await axios.post('/log/createMessage', {
      senderId,
      accountId,
      senderType,
      subject,
      details,
      notes
    });
    console.log("Log message sent successfully");
  } catch (error) {
    console.error("Error logging form submission: ", error);
  }
};

export default logFormSubmission;
