import React, { useState, useEffect } from 'react';
import axios from '../../../../../../../apiConfig';
import FormAccountsReportUI from './FormAccountsReportUI';
import { useServiceData } from '../../../../../../../Contexts/ServiceDataContext';

const FormAccountsReport = ({ setReportData, userId, userType, setPdfComponent }) => {
    const { accountReportOptions, fetchAccountReportOptions } = useServiceData();
    const [selectedAccountIds, setSelectedAccountIds] = useState([]);
    const [selectedAccountOption, setSelectedAccountOption] = useState([]);
    const [selectedRadio, setSelectedRadio] = useState('');

    useEffect(() => {
        fetchAccountReportOptions();
    }, [fetchAccountReportOptions]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await axios.post('/admin/generateAccountsReport', { selectedAccountIds });
            setReportData(result.data);
            setPdfComponent('ACCOUNTS_PDF');
            console.log('Results: ', result);
        } catch (error) {
            console.log('Error in frontend trycatch for /admin/generateAccountsReport');
        }
    };

    const handleRadioSelect = (e) => {
        const value = e.target.value;
        setSelectedRadio(value);
        switch (value) {
            case 'date_created':
                setSelectedAccountOption(accountReportOptions[0]);
                break;
            case 'alphabetical':
                setSelectedAccountOption(accountReportOptions[1]);
                break;
            case 'nickname':
                setSelectedAccountOption(accountReportOptions[2]);
                break;
            default:
                setSelectedAccountOption(accountReportOptions[0]);
        }
    };

    const handleAccountSelect = (e) => {
        const selectedOptions = e.target.selectedOptions;
        const ids = Array.from(selectedOptions).map((option) => option.value);
        setSelectedAccountIds(ids);
    };

    return (
        <FormAccountsReportUI
            selectedRadio={selectedRadio}
            selectedAccountOption={selectedAccountOption}
            handleRadioSelect={handleRadioSelect}
            handleAccountSelect={handleAccountSelect}
            handleSubmit={handleSubmit}
        />
    );
};

export default FormAccountsReport;
