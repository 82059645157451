import axios from '../../apiConfig';

const logActivationFormSubmission = async ({ senderId = null, accountId = null, senderType = 'server', subject, details, notes = '' }) => {
    try {
      await axios.post('/auth/createMessage', {
        senderId,
        accountId,
        senderType,
        subject,
        details,
        notes
      });
      console.log("Log message sent successfully");
    } catch (error) {
      console.error("Error logging activation form submission: ", error);
    }
  };

  export default logActivationFormSubmission;