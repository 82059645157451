import React, { createContext, useContext, useState, useEffect } from 'react';

const DashboardContext = createContext();

//Controls what components are being viewed within the set stage
export const useDashboard = () => {
    const context =  useContext(DashboardContext);
    if (!context) {
        throw new Error('useDisplay must be used within a DisplayProvider');
    }
    return context;
}


// Handles what component of the client and admin dashboards is displayed
export const DashboardProvider = ({ children }) => {
    const [dashboard, setDashboard] = useState('DEFAULT');

    useEffect(() => {
        const handleLogout = () => {
            setDashboard('DEFAULT');
        };
    
        window.addEventListener('logout-event', handleLogout);
    
        return () => {
            window.removeEventListener('logout-event', handleLogout);
        };
    }, []);

    const value = {
        dashboard,
        setDashboard,
    };

    return (
        <DashboardContext.Provider value={value}>
            {children}
        </DashboardContext.Provider>
    );    
}
