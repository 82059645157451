import React from 'react';
import styles from './modify_account.module.css';

const FormModifyAccountUI = ({
    activeFilter,
    setActiveFilter,
    selectedAccountId,
    setSelectedAccountId,
    filteredAccounts,
    accountData,
    handleInputChange,
    handleSubmit,
    userInterfaceMessage,
    userInterfaceMessageClass,
    notes,
    setNotes
}) => {
    return (
        <div className={styles.outer_container}>
            <div className={styles.form_container}>
                <form onSubmit={handleSubmit} className={styles.CLIENT_ACCOUNT_MGMT_form}>
                    <div className={styles.header_container}>
                        <h3 className={styles.header}>Modify Account</h3>
                    </div>
                    <label className={`form-label ${styles.label}`} htmlFor='filter'>
                        Show Active/Inactive Accounts
                        <select value={activeFilter} className={`form-select ${styles.input}`} name='filter' onChange={e => setActiveFilter(e.target.value)}>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='account'>
                        Select Account
                        <select value={selectedAccountId} className={`form-select ${styles.input}`} name='account' onChange={e => setSelectedAccountId(e.target.value)}>
                            <option value="">Select an Account</option>
                            {filteredAccounts.map(account => (
                                <option key={account.account_id} value={account.account_id}>
                                    {account.street_address}, {account.city}
                                </option>
                            ))}
                        </select>
                    </label>

                    <label className={`form-label ${styles.label}`} htmlFor='type'>
                        Type
                        <select name="type" className={`form-select ${styles.input}`} value={accountData.type} onChange={handleInputChange}>
                            <option value="Commercial">Commercial</option>
                            <option value="Residential">Residential</option>
                            <option value="HOA">HOA</option>
                        </select>
                    </label>

                    <label className={`form-label ${styles.label}`} htmlFor='nickname'>
                        Nickname:
                        <input type="text" className={`form-control ${styles.input}`} name="nickname" value={accountData.nickname} onChange={handleInputChange} />
                    </label>

                    <label className={`form-label ${styles.label}`} htmlFor='streetAddress'>
                        Street Address:
                        <input type="text" className={`form-control ${styles.input}`} name="streetAddress" value={accountData.streetAddress} onChange={handleInputChange} />
                    </label>

                    <label className={`form-label ${styles.label}`} htmlFor='zipCode'>
                        Zip Code:
                        <input type="text" className={`form-control ${styles.input}`} name="zipCode" value={accountData.zipCode} onChange={handleInputChange} />
                    </label>

                    <label className={`form-label ${styles.label}`} htmlFor='city'>
                        City:
                        <input type="text" className={`form-control ${styles.input}`} name="city" value={accountData.city} onChange={handleInputChange} />
                    </label>

                    <label className={`form-label ${styles.label}`} htmlFor='county'>
                        County:
                        <input type="text" className={`form-control ${styles.input}`} name="county" value={accountData.county} onChange={handleInputChange} />
                    </label>

                    <label className={`form-label ${styles.label}`} htmlFor='state'>
                        State:
                        <select name="state" className={`form-select ${styles.input}`} value={accountData.state} onChange={handleInputChange} required>
                            <option value="KY">Kentucky</option>
                            <option value="IN">Indiana</option>
                        </select>
                    </label>

                    <label className={`form-label ${styles.label}`} htmlFor='price'>
                        Default Price:
                        <input type="number" className={`form-control ${styles.input}`} name="price" value={accountData.price} onChange={handleInputChange} />
                    </label>

                    <label className={`form-label ${styles.label}`} htmlFor='active'>
                        Active (0 or 1):
                        <input type="number" className={`form-control ${styles.input}`} name="active" value={accountData.active} onChange={handleInputChange} />
                    </label>

                    <label className={`form-label ${styles.label}`} htmlFor='serviceFrequency'>
                        Service Frequency (per week):
                        <input type="number" className={`form-control ${styles.input}`} name="serviceFrequency" value={accountData.serviceFrequency} onChange={handleInputChange} />
                    </label>
                    {userInterfaceMessage && (<div className={userInterfaceMessageClass}>{userInterfaceMessage}</div>)}
                    <label className={`form-label ${styles.label}`} htmlFor='notes'>
                        Additional Notes:
                        <input type='text' className={`form-control ${styles.input}`} name='notes' value={notes} onChange={(e) => setNotes(e.target.value)} />
                    </label>
                    <div>
                        <button className={`btn ${styles.update_button}`} type="submit">Save Changes</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FormModifyAccountUI;
