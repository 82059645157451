import React from 'react';
import styles from './business_report.module.css';

const FormBusinessReportUI = ({
    dateSelectionType,
    selectedDate,
    startDate,
    endDate,
    formMaxDate,
    handleDateSelectionChange,
    handleSelectedDateChange,
    handleStartDateChange,
    handleEndDateChange,
    handleSubmit,
    uiMessage
}) => {
    const renderDateInput = () => {
        switch (dateSelectionType) {
            case 'current':
                return null;
            case 'selected':
                return (
                    <label>
                        Week of Selected Date (Monday-Monday)
                        <input
                            className={`form-control ${styles.input}`}
                            type="date"
                            value={selectedDate}
                            onChange={handleSelectedDateChange}
                            max={formMaxDate}
                        />
                    </label>
                );
            case 'timeframe':
                return (
                    <div className={styles.custom_inputs_container}>
                        <label>
                            Start Date:
                            <input
                                className={`form-control ${styles.input}`}
                                type="date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                max={formMaxDate}
                            />
                        </label>
                        <label>
                            End Date:
                            <input
                                className={`form-control ${styles.input}`}
                                type="date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                max={formMaxDate}
                            />
                        </label>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form_container}>
            <div className={styles.header_container}>
                <h3 className={styles.header}>Select a Timeframe</h3>
            </div>
            <div className={styles.inputs_container}>
                <label className={`form-check-label ${styles.label}`} htmlFor="dateSelectionType1">
                    <input
                        type="radio"
                        name="dateSelectionType"
                        value="current"
                        className={`form-check-input ${styles.radio_input}`}
                        checked={dateSelectionType === 'current'}
                        onChange={() => handleDateSelectionChange('current')}
                    />
                    Current Week
                </label>
                <label className={`form-check-label ${styles.label}`} htmlFor="dateSelectionType2">
                    <input
                        type="radio"
                        name="dateSelectionType"
                        value="selected"
                        className={`form-check-input ${styles.radio_input}`}
                        checked={dateSelectionType === 'selected'}
                        onChange={() => handleDateSelectionChange('selected')}
                    />
                    Specific Week
                </label>
                <label className={`form-check-label ${styles.label}`} htmlFor="dateSelectionType3">
                    <input
                        type="radio"
                        name="dateSelectionType"
                        value="timeframe"
                        className={`form-check-input ${styles.radio_input}`}
                        checked={dateSelectionType === 'timeframe'}
                        onChange={() => handleDateSelectionChange('timeframe')}
                    />
                    Custom
                </label>
            </div>
            {renderDateInput()}
            <div className={styles.button_container}>
                <button className={`btn ${styles.submit_button}`} type="submit">Generate</button>
            </div>
            {uiMessage}
        </form>
    );
};

export default FormBusinessReportUI;
