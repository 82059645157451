import React, { useState, useEffect } from 'react';
import axios from '../../apiConfig';
import { useStage } from '../../Contexts/StageContext';
import { useDashboard } from '../../Contexts/DashboardContext';
import { useAccountData } from '../../Contexts/AccountDataContext';
import './CLIENT_HEADER.css';

//Provides anchors to control the dashboard view for everything except 'ACCOUNT_DETAILS'
const CLIENT_HEADER = () => {
    const [dropdownActive, setDropdownActive] = useState(false);
    const { stage, setStage } = useStage();
    const { dashboard, setDashboard } = useDashboard();
    const { selectedAccount } = useAccountData();
    const [ componentName, setComponentName ] = useState('Accounts');

    useEffect(() => {
        if (dashboard === 'ACCOUNT_DETAILS' && selectedAccount) {
            setComponentName(`${selectedAccount.street_address}, ${selectedAccount.city}`);
        }
    }, [dashboard, selectedAccount]);

    const handleLogout = () => {
        axios.get('/auth/logout')
            .then(response => {
                setStage('LAUNCH');
                setDashboard('DEFAULT');
                localStorage.removeItem('jwtToken');
            })
            .catch(error => {
                console.error('Error logging out:', error)
            })
    }

    return (
        <div className='header_container'>
            <div className='header_content_container'>
                <div className='dropdown_and_component_name_container'>
                    <div className={`dropdown ${dropdownActive ? 'active' : ''}`} onClick={() => setDropdownActive(!dropdownActive)}>
                        <div className="dropdown_bar"></div>
                        <div className="dropdown_bar"></div>
                        <div className="dropdown_bar"></div>
                    </div>
                    {dropdownActive && (
                        <div className="dropdown_menu">
                            <div className='menu_item' onClick={() => { 
                                setDashboard('ACCOUNTS'); 
                                setComponentName('Accounts');
                                setDropdownActive(false); 
                            }}>
                                Accounts
                            </div>
                            <div className='menu_item' onClick={() => { 
                                setDashboard('INVOICE_HISTORY'); 
                                setComponentName('Invoice History');
                                setDropdownActive(false); 
                            }}>
                                Invoice History
                            </div>
                            <div className='menu_item' onClick={() => { 
                                setDashboard('BILLING'); 
                                setComponentName('Online Billing');
                                setDropdownActive(false); 
                            }}>
                                Online Billing
                            </div>
                        </div>
                    )}
                    <div className='component_name'>{componentName}</div>
                </div>
                <button className='button' onClick={() => handleLogout()}>Logout</button>
            </div>
        </div>
    )
}

export default CLIENT_HEADER;
