import React from 'react';
import styles from './create_client.module.css';

const FormCreateClientUI = ({
    formData,
    notes,
    userInterfaceMessage,
    userInterfaceMessageClass,
    handleChange,
    handleReset,
    handleSubmit,
    setNotes
}) => {
    return (
        <div className={styles.outer_container}>
            <div className={styles.button_container}>
                <button type="button" className={`btn ${styles.button}`} onClick={handleReset}>Clear Form</button>
            </div>
            <div className={styles.form_container}>
                <form className={styles.CLIENT_ACCOUNT_MGMT_form} onSubmit={handleSubmit}>
                    <div className={styles.header_container}>
                        <h3 className={styles.header}>Client Information</h3>
                    </div>
                    <label className={`form-label ${styles.label}`} htmlFor='firstName'>
                        First Name:
                        <input type="text" className={`form-control ${styles.input}`} name="firstName" value={formData.firstName} onChange={handleChange} required />
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='lastName'>
                        Last Name:
                        <input type="text" className={`form-control ${styles.input}`} name="lastName" value={formData.lastName} onChange={handleChange} required />
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='phoneNumber'>
                        Phone Number:
                        <input type="text" className={`form-control ${styles.input}`} name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='email'>
                        Email:
                        <input type="email" className={`form-control ${styles.input}`} name="email" value={formData.email} onChange={handleChange} required />
                    </label>
                    {userInterfaceMessage && (<div className={`${styles.user_interface_message_container} ${styles[userInterfaceMessageClass]}`}>{userInterfaceMessage}</div>)}
                    <label className={`form-label ${styles.label}`} htmlFor='notes_input'>
                        Additional Notes:
                        <input type='text' className={`form-control ${styles.input}`} value={notes} onChange={(e) => setNotes(e.target.value)} />
                    </label>
                    <button className={`btn ${styles.create_button}`} type="submit">Create Client</button>
                </form>
            </div>
        </div>
    );
};

export default FormCreateClientUI;
