exports.parseDate = (timestamp) => {
    //2024-01-31 --> 01-31-2024
    return timestamp.match(/(\d{4}-\d{2}-\d{2})/)[0].replace(/(\d{4})-(\d{2})-(\d{2})/, '$2-$3-$1');
}

exports.parseTime = (timestamp) => {
    const timeMatch = timestamp.match(/(\d{2}):(\d{2}):(\d{2})/);
    let [_, hour, minute] = timeMatch; 
    hour = parseInt(hour, 10);
    const suffix = hour >= 12 ? 'P.M.' : 'A.M.';
    const parsedHour = hour % 12 || 12;
    return `${parsedHour}:${minute} ${suffix}`;
}