import React from 'react';
import styles from './FormModifyClient.module.css';

const FormModifyClientPresentation = ({
    clients,
    notes,
    clientData,
    displayMessage,
    handleSelect,
    handleChange,
    handleSubmit,
    setNotes
}) => {
    return (
        <div className={styles.outer_container}>
            <div className={styles.form_container}>
                <form onSubmit={handleSubmit} className={styles.CLIENT_ACCOUNT_MGMT_form}>
                    <div className={styles.header_container}>
                        <h3 className={styles.header}>Modify Client</h3>
                    </div>
                    <label className={`form-label ${styles.label}`} htmlFor='client'>
                        Select Client:
                        <select value={clientData.clientId} className={`form-select ${styles.input}`} name='client' onChange={handleSelect}>
                            <option value="">Select a Client</option>
                            {clients.map(client => (
                                <option key={client.user_id} value={client.user_id}>
                                    {client.first_name} {client.last_name}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='firstName'>
                        First Name:
                        <input type="text" className={`form-control ${styles.input}`} name="firstName" value={clientData.firstName} onChange={handleChange} />
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='lastName'>
                        Last Name:
                        <input type="text" className={`form-control ${styles.input}`} name="lastName" value={clientData.lastName} onChange={handleChange} />
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='phoneNumber'>
                        Phone Number:
                        <input type="tel" className={`form-control ${styles.input}`} name="phoneNumber" value={clientData.phoneNumber} onChange={handleChange} />
                    </label>
                    <label className={`form-label ${styles.label}`} htmlFor='email'>
                        Email:
                        <input type="email" className={`form-control ${styles.input}`} name="email" value={clientData.email} onChange={handleChange} />
                    </label>
                    {displayMessage && (<div>{displayMessage}</div>)}
                    <label className={`form-label ${styles.label}`} htmlFor='notes_input'>
                        Additional Notes:
                        <input type='text' className={`form-control ${styles.input}`} value={notes} onChange={(e) => setNotes(e.target.value)} />
                    </label>
                    <div>
                        <button className={`btn ${styles.update_button}`} type="submit">Save Changes</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FormModifyClientPresentation;
