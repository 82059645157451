import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, BlobProvider } from '@react-pdf/renderer';
import logo from '../../../../../../Images/Logo-Design-Blacksmith-Revamp.png';
import getTodaysDate from '../../../../../../Utilities/Functions/getTodaysDate';
import getCurrentBusinessCycle from '../../../../../../Utilities/Functions/getCurrentBusinessCycle';
import { parseDate, parseTime } from '../../../../../../Utilities/Functions/parseDateTime';

// Styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        alignItems: 'center'
    },
    section: {
        marginTop: '.1in',
        marginBottom: '.1in',
        borderBottomWidth: 2,
        borderColor: 'black',
        borderStyle: 'solid',
        width: '90%',
    },
    logo: {
        width: '1.5in',
        height: '0.48in',
    },
    header: {
        flexDirection:'row',
        justifyContent: 'space-between',
        // borderWidth: 2,
        // borderColor: 'black',
        // borderStyle: 'solid',
        width: '100%',
        height: '0.8in'
    },
    headerBlock: {
        width: '50%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
        // borderWidth: 2,
        // borderColor: 'red',
        // borderStyle: 'solid',
    },
    title: {
        fontSize: '15pt',
        textDecoration: 'underline',
        fontWeight: 'bold',
        left: '0.25in',
        marginBottom: '.10in'
    },
    sectionDetails: {
        fontSize: '13pt',
        marginTop: '.05in',
        marginBottom: '.02in',
        width: '100%',
    },
    sectionSubDetails: {
        fontSize: '11pt',
        marginBottom: '.05in',
        left: '.2in'
    },
    layout: {
        row: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: '11pt',
            marginBottom: '.05in',
        },
        bullet: {
            height: '100%',
        }
    }
});

const ListItem = ({ children }) => {
    return (
        <View style={styles.layout.row}>
            <View style={styles.layout.bullet}>
                <Text>{'\u2022' + " "}</Text>
            </View>
            <Text style={styles.sectionSubDetails}>{children}</Text>
        </View>
    )
}

const INVOICE_REPORT = ({ reportData }) => {

    let todaysDate = getTodaysDate(new Date());
    let currentBusinessCycle = getCurrentBusinessCycle();

    return (
        <PDFViewer style={{ width: '80%', height: '95%' }}>
            <Document title={`Invoice #${reportData.unpaidInvoiceDetails.invoice_id} | ${reportData.client.first_name} ${reportData.client.last_name} | ${todaysDate}`} author='Sharps Lawncare' subject='Invoice Report'>
                <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        <View style={styles.headerBlock}>
                            <Image style={[styles.logo, {left: '.25in'}]} src={logo} ></Image>
                        </View>
                        <View style={[styles.headerBlock, {fontSize: '10pt'}]}>
                            <Text style={[{fontSize: '17pt', fontWeight: 'bold', textDecoration: 'underline'}]}>
                                Invoice Report
                            </Text>
                            <Text>
                                {todaysDate}
                            </Text>
                        </View>
                    </View>
                    <View style={{ height: '.35in', width: '100%', flexDirection: 'row', justifyContent: 'center'}}>
                        <View style={{ height: '1pt', width: '90%', borderWidth: '1.5pt', borderColor: 'black', borderStyle: 'solid' , borderRadius: '5pt', backgroundColor: 'black' }}></View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.title}>
                            Invoice #{reportData.unpaidInvoiceDetails.invoice_id}:
                        </Text>
                        <Text style={styles.sectionDetails}>
                            Client: {reportData.client.first_name} {reportData.client.last_name}
                        </Text>
                        <Text style={styles.sectionDetails}>
                            Date Issued: {parseDate(reportData.unpaidInvoiceDetails.date_issued)} {parseTime(reportData.unpaidInvoiceDetails.date_issued)}
                        </Text>
                        <Text style={styles.sectionDetails}>
                            Due Date: {parseDate(reportData.unpaidInvoiceDetails.due_date)} {parseTime(reportData.unpaidInvoiceDetails.due_date)}
                        </Text>
                        <Text style={styles.sectionDetails}>
                            Total Charge: ${reportData.unpaidInvoiceDetails.amount_charged}
                        </Text>
                        <Text style={styles.sectionDetails}>
                            Charge Remaining: ${reportData.unpaidInvoiceDetails.charge_outstanding}
                        </Text>
                    </View>
                        <Text style={styles.title}>
                            Unpaid Account Services:
                        </Text>
                    <View style={styles.section}>
                    {reportData.relatedAccountServices.map(account => (
                            <Text key={account.account_id} style={styles.sectionDetails}>
                                {account.street_address}, {account.city}, {account.state} {'\n'}
                                {account.services.length != 0 ? account.services.map((service) => (
                                    <ListItem key={service.service_id}>
                                        {service.service_type} {parseDate(service.timestamp)} {parseTime(service.timestamp)} {'\n       '}| Charge: ${service.amount_charged} {'\n       '}| Outstanding: ${service.charge_outstanding} {'\n'} {'\n'}
                                    </ListItem>
                                ))
                            : <ListItem style={[styles.sectionSubDetails, {textIndent: '20pt'}]}>All Services Paid {'\n'}</ListItem>}
                            </Text>
                    ))}
                    </View>
                    
                </Page>
            </Document>
        </PDFViewer>
    )
}

export default INVOICE_REPORT;