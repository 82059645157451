import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from '../apiConfig'; 

export const ClientDataContext = createContext();

export const useClientData = () => useContext(ClientDataContext);

// handles data related to the individual client
export const ClientDataProvider = ({ children }) => {
    const [invoices, setInvoices] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [unpaidInvoices, setUnpaidInvoices] = useState([]);

    useEffect(() => {
        const fetchClientData = async () => {
            try {
                const invoicesResponse = await axios.get('/client/invoices_RelatedServices_Accounts');
                const accountsResponse = await axios.get('/client/accounts');
                const unpaidInvoicesResponse = await axios.get('/client/unpaidInvoicesWithRelatedServices');

                setInvoices(invoicesResponse.data.invoices || []);
                setAccounts(accountsResponse.data || []);
                setUnpaidInvoices(unpaidInvoicesResponse.data || []);
            } catch (error) {
                console.error('Error fetching client data:', error);
            }
        };

        fetchClientData();
    }, []);

    return (
        <ClientDataContext.Provider value={{ invoices, accounts, setAccounts, unpaidInvoices, setUnpaidInvoices }}>
            {children}
        </ClientDataContext.Provider>
    );
};
