import React, { useEffect } from "react";

import FormCreateClientWithAccount from './Modules/FormCreateClientWithAccount/FormCreateClientWithAccount';
import FormCreateAccount from "./Modules/FormCreateAccount/FormCreateAccount";
import FormCreateClient from './Modules/FormCreateClient/FormCreateClient';
import FormModifyAccount from './Modules/FormModifyAccount/FormModifyAccount';
import FormModifyClientOutstanding from "./Modules/FormModifyClientOutstanding/FormModifyClientOutstanding";

import FormModifyClientContainer from "./Modules/FormModifyClient/FormModifyClientContainer";

import { useComponent } from '../../../../Contexts/ComponentContext';
import { useServiceData } from "../../../../Contexts/ServiceDataContext";
import { useUser } from "../../../../Contexts/UserContext";

import styles from './CLIENT_ACCOUNT_MGMT.module.css';
import FormDeleteClient from "./Modules/FormDeleteClient/FormDeleteClient";
import FormDeleteAccount from "./Modules/FormDeleteAccount/FormDeleteAccount";




//View for forms related to CRUD operations on clients, accounts, and eventually services / invoices
const CLIENT_ACCOUNT_MGMT = () => {

    const { accounts, clients, refreshBusinessData, fetchClients } = useServiceData();
    const { userId, userType } = useUser();
    const { component, setComponent } = useComponent();

    useEffect(() => {
        setComponent('CREATE_CLIENT_WITH_ACCOUNT');
    }, [setComponent]);

    const directories = [
        { componentName: 'CREATE_CLIENT_WITH_ACCOUNT', buttonTitle: 'Create Client with Account' },
        { componentName: 'CREATE_CLIENT', buttonTitle: 'New Client' },
        { componentName: 'CREATE_ACCOUNT', buttonTitle: 'New Account' },
        { componentName: 'MODIFY_CLIENT', buttonTitle: 'Modify Client' },
        { componentName: 'MODIFY_ACCOUNT', buttonTitle: 'Modify Account' },
        { componentName: 'MODIFY_CLIENT_OUTSTANDING', buttonTitle: 'Cash Payment'},
        { componentName: 'DELETE_CLIENT', buttonTitle: 'Delete Client'},
        { componentName: 'DELETE_ACCOUNT', buttonTitle: 'Delete Account'}
    ];

    const directoryMapping = {
        'CREATE_CLIENT_WITH_ACCOUNT': <FormCreateClientWithAccount userId={userId} userType={userType} refreshBusinessData={refreshBusinessData} />,
        'CREATE_CLIENT': <FormCreateClient userId={userId} userType={userType} refreshBusinessData={refreshBusinessData} />,
        'CREATE_ACCOUNT': <FormCreateAccount clients={clients} userId={userId} userType={userType} refreshBusinessData={refreshBusinessData} />,
        'MODIFY_CLIENT': <FormModifyClientContainer clients={clients} userId={userId} userType={userType} refreshBusinessData={refreshBusinessData} fetchClients={fetchClients} />,
        'MODIFY_ACCOUNT': <FormModifyAccount accounts={accounts} userId={userId} userType={userType} refreshBusinessData={refreshBusinessData} />,
        'MODIFY_CLIENT_OUTSTANDING': <FormModifyClientOutstanding clients={clients} userId={userId} userType={userType} refreshBusinessData={refreshBusinessData} />,
        'DELETE_CLIENT': <FormDeleteClient clients={clients} accounts={accounts} userId={userId} userType={userType} refreshBusinessData={refreshBusinessData} />,
        'DELETE_ACCOUNT': <FormDeleteAccount clients={clients} accounts={accounts} userId={userId} userType={userType} refreshBusinessData={refreshBusinessData} />
    };

    const renderContent = () => {
        return directoryMapping[component] || null;
    }

    return (
        <div className={styles.CLIENT_ACCOUNT_MGMT_container_outer}>
            <div className={styles.CLIENT_ACCOUNT_MGMT_container_middle}>
                <div className={styles.CLIENT_ACCOUNT_MGMT_container_inner}>
                    <div className={styles.tabs_container_outer}>
                        <div className={styles.tabs_container_inner}>
                            <div className={styles.header_container}>
                                Forms
                            </div>
                        {directories.map((directory, index) => (
                            <button key={index} className={`btn ${component === directory.componentName ? styles.active : styles.tab}`} onClick={() => {
                                setComponent(directory.componentName);
                            }}>
                                {directory.buttonTitle}
                            </button>
                        ))}
                        </div>
                    </div>
                    <div className={styles.forms_container}>
                            {renderContent()}
                        <div className="details_container"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CLIENT_ACCOUNT_MGMT;