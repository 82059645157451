import React from 'react';

import styles from './CSS/account_notifs.module.css';

const ACCOUNT_NOTIFS = ({}) => {
    

    return (
        <div className={styles.notifs_container}>
            
        </div>
    )
}

export default ACCOUNT_NOTIFS;