import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import LAUNCH_MAIN from './Components/Launch_Page/LAUNCH_MAIN';
import CLIENT_DASHBOARD from './Components/Client/CLIENT_DASHBOARD';
import ADMIN_DASHBOARD from './Components/Admin/ADMIN_DASHBOARD';

import { StageProvider } from './Contexts/StageContext';
import { DashboardProvider } from './Contexts/DashboardContext';
import { ComponentProvider } from './Contexts/ComponentContext';
import { ServiceDataProvider } from './Contexts/ServiceDataContext';
import { AccountDataProvider } from './Contexts/AccountDataContext';
import { ClientDataProvider } from './Contexts/ClientDataContext';
import { UserProvider } from './Contexts/UserContext';
import { ConfirmationProvider } from './Contexts/Confirmation';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


import './App.css';

const stripePromise = loadStripe('pk_test_51ODDiaDYtvAkOWH6gFYzmreWiluyQj0zTjOl3Vz9SZEktYBUi57LdEnu6pABqFaJPLWuPoKlEWkINlhE1b3ceM4j00m89DA9os');
//refactor AccountDataProvider into ClientDataProvider
function App() {
  return (
    
    <Router className="Router">
      <StageProvider>
      <DashboardProvider>
      <ComponentProvider>
      <UserProvider>
      <ConfirmationProvider>
      <div className="App">
        
          <Routes className='routes'>         
              <Route path='/' element={<LAUNCH_MAIN />} exact />
              <Route path='/client' element={
                <ClientDataProvider> 
                <AccountDataProvider>
                <Elements stripe={stripePromise}>
                  <CLIENT_DASHBOARD />
                </Elements>
                </AccountDataProvider>
                </ClientDataProvider>
              } />
              <Route 
                path='/admin' 
                element={
                  <ServiceDataProvider>
                    <ADMIN_DASHBOARD />
                  </ServiceDataProvider>
                } 
              />
          </Routes>
        
      </div>
      </ConfirmationProvider>
      </UserProvider>
      </ComponentProvider>
      </DashboardProvider>
      </StageProvider>
    </Router>
  );
}

export default App;
