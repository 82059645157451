import React from 'react';

import styles from './form_delete_account.module.css';

const Dropdown = ({ handleChange, formData, accounts }) => (
    <div>
        <label className={`form-label ${styles.label}`} htmlFor='accountId'>
            Select Account:
        </label>
        <select className={`form-select form-select-lg mb-3 ${styles.input}`} name='accountId' onChange={handleChange} value={formData.accountId}>
            <option value=''>List of Accounts</option>
            {accounts.map(account => (
                <option key={account.account_id} value={account.account_id}>
                    {account.street_address}, {account.city}, {account.state}
                </option>
            ))}
        </select>
    </div>
);

export default function FormDeleteAccountUI({ 
    formData, 
    uiMessage, 
    handleSubmit, 
    handleChange, 
    notes,
    setNotes, 
    accounts, 
    accountDetails, 
    clientDetails }) {
        return (
            <div className={styles.outer_container}>
                <div className={styles.form_container}>
                    {uiMessage && <div className={styles.ui_message}>{uiMessage}</div>}
                    <form onSubmit={handleSubmit}>
                        <div className={styles.header_container}>
                            <h3 className={styles.header}>Delete Account</h3>
                        </div>
                        <Dropdown handleChange={handleChange} formData={formData} accounts={accounts} />
                        {accountDetails && (
                            <div>
                                <p>Account: {accountDetails.street_address}, {accountDetails.city}, {accountDetails.state}</p>
                                <p>Related Client: {clientDetails.first_name} {clientDetails.last_name}</p>
                                <label className={`form-label ${styles.label}`} htmlFor='notes'>
                                    Deletion Notes:
                                    <input type='text' className={`form-control ${styles.input}`} value={notes} onChange={(e) => setNotes(e.target.value)} />
                                </label>
                                
                                <button className={`btn ${styles.delete_button}`} type="submit">Delete Account</button>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        )
}