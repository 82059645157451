import React from 'react';
import styles from './form_delete_client.module.css';

const Dropdown = ({ handleChange, formData, clients }) => (
    <div>
        <label className={`form-label ${styles.label}`} htmlFor='clientId'>
            Select Client:
        </label>
        <select className={`form-select form-select-lg mb-3 ${styles.input}`} name='clientId' onChange={handleChange} value={formData.clientId}>
            <option value=''>List of Clients</option>
            {clients.map(client => (
                <option key={client.user_id} value={client.user_id}>
                    {client.first_name} {client.last_name}
                </option>
            ))}
        </select>
    </div>
);

const FormDeleteClientUI = ({
    formData,
    notes,
    uiMessage,
    handleChange,
    handleSubmit,
    setNotes,
    clients,
    client,
    clientAccounts
}) => {
    return (
        <div className={styles.outer_container}>
            <div className={styles.form_container}>
                {uiMessage && <div className={styles.ui_message}>{uiMessage}</div>}
                <form onSubmit={handleSubmit}>
                    <div className={styles.header_container}>
                        <h3 className={styles.header}>Delete Client</h3>
                    </div>
                    <Dropdown handleChange={handleChange} formData={formData} clients={clients} />
                    {client && (
                        <div>
                            <p>Client: {client.first_name} {client.last_name}</p>
                            <p>Email: {client.email}</p>
                            <p>Phone: {client.phone_number}</p>
                            <label className={`form-label ${styles.label}`} htmlFor='notes'>
                                Deletion Notes:
                                <input type='text' className={`form-control ${styles.input}`} value={notes} onChange={(e) => setNotes(e.target.value)} />
                            </label>
                            <h4>Accounts</h4>
                            <ul>
                                {clientAccounts.map(account => (
                                    <li key={account.account_id}>
                                        {account.street_address}, {account.city}
                                    </li>
                                ))}
                            </ul>
                            <button className={`btn ${styles.delete_button}`} type="submit">Delete Client</button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
}

export default FormDeleteClientUI;
