import React from 'react';
import styles from './account_report.module.css';

const RadioBox = ({ selectedRadio, handleRadioSelect }) => (
    <div className={styles.radiobox_container}>
        <div className={styles.header_container}>
            <h3 className={styles.header}>Sort Options</h3>
        </div>
        <div className={`form-check form-check-inline ${styles.input_container}`}>
            <input
                className={`form-check-input ${styles.radio_input}`}
                type="radio"
                name="date_created"
                id="flexRadioDefault1"
                onChange={handleRadioSelect}
                value="date_created"
                checked={selectedRadio === 'date_created'}
            />
            <label className={`form-check-label ${styles.label}`} htmlFor="flexRadioDefault1">
                Date Created
            </label>
        </div>
        <div className={`form-check form-check-inline ${styles.input_container}`}>
            <input
                className={`form-check-input ${styles.radio_input}`}
                type="radio"
                name="alphabetical"
                id="flexRadioDefault2"
                onChange={handleRadioSelect}
                value="alphabetical"
                checked={selectedRadio === 'alphabetical'}
            />
            <label className={`form-check-label ${styles.label}`} htmlFor="flexRadioDefault2">
                Alphabetical
            </label>
        </div>
        <div className={`form-check form-check-inline ${styles.input_container}`}>
            <input
                className={`form-check-input ${styles.radio_input}`}
                type="radio"
                name="nickname"
                id="flexRadioDefault3"
                onChange={handleRadioSelect}
                value="nickname"
                checked={selectedRadio === 'nickname'}
            />
            <label className={`form-check-label ${styles.label}`} htmlFor="flexRadioDefault3">
                Nickname
            </label>
        </div>
    </div>
);

const FormAccountsReportUI = ({
    selectedRadio,
    selectedAccountOption,
    handleRadioSelect,
    handleAccountSelect,
    handleSubmit
}) => (
    <div className={styles.form_account_report_container}>
        <RadioBox selectedRadio={selectedRadio} handleRadioSelect={handleRadioSelect} />
        <form className={styles.dropdown_form_container} onSubmit={handleSubmit}>
            <div className={styles.options_list}>
                <label className="title" htmlFor="dropdown_select">
                    Multi-Select: {`(Hold Ctrl + Click)`}
                </label>
                <select
                    className={`form-control ${styles.dropdown}`}
                    id="dropdown_select"
                    onChange={handleAccountSelect}
                    multiple
                >
                    {selectedAccountOption.map((account) => (
                        <option
                            className={styles.option}
                            key={account.account_id}
                            value={account.account_id}
                        >
                            {account.street_address}, {account.city}, {account.state} —{' '}
                            {account.nickname ? account.nickname : ''}
                        </option>
                    ))}
                </select>
            </div>
            <div className={styles.button_container}>
                <button className={`btn ${styles.submit_button}`} type="submit">
                    Generate
                </button>
            </div>
        </form>
    </div>
);

export default FormAccountsReportUI;
