import React, { useState, useEffect } from 'react';

import FORM_CONTACT from './FORM_CONTACT';

import styles from './THIRD_SECTION.module.css';

const THIRD_SECTION = () => {
return (
        <div className={styles.third_section_container}>
            <div className={styles.placement}>
                <div className={styles.section_container}>

                </div>
                <div className={styles.section_container}>
                    
                </div>
            </div>
        </div>
    )
}

export default THIRD_SECTION;