import React, { useEffect, useState } from 'react';
import axios from '../../../../../../../apiConfig';
import FormInvoiceReportUI from './FormInvoiceReportUI';
import { useServiceData } from '../../../../../../../Contexts/ServiceDataContext';

const FormInvoiceReport = ({ userId, userType, setPdfComponent, setReportData }) => {
    const { invoiceReportOptions, fetchInvoiceReportOptions } = useServiceData();
    const [selectedInvoiceOption, setSelectedInvoiceOption] = useState([]);
    const [selectedInvoiceID, setSelectedInvoiceID] = useState(0);
    const [selectedRadio, setSelectedRadio] = useState('');

    useEffect(() => {
        fetchInvoiceReportOptions();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await axios.post('/admin/generateInvoiceReport', { selectedInvoiceID });
            setReportData(result.data);
            setPdfComponent('INVOICE_PDF');
            console.log("Front end results: ", result.data);
        } catch (InvoiceReportError) {
            console.error('Error Generating Invoice Report: ', InvoiceReportError);
        }
    };

    const handleRadioSelect = (e) => {
        const value = e.target.value;
        setSelectedRadio(value);
        switch (value) {
            case 'all':
                setSelectedInvoiceOption(invoiceReportOptions[0]);
                break;
            case 'incycle':
                setSelectedInvoiceOption(invoiceReportOptions[1]);
                break;
            case 'overdue':
                setSelectedInvoiceOption(invoiceReportOptions[2]);
                break;
            default:
                setSelectedInvoiceOption(invoiceReportOptions[0]);
        }
    };

    const handleInvoiceSelect = (e) => {
        setSelectedInvoiceID(e.target.value);
    };

    return (
        <FormInvoiceReportUI
            selectedInvoiceOption={selectedInvoiceOption}
            selectedInvoiceID={selectedInvoiceID}
            handleInvoiceSelect={handleInvoiceSelect}
            handleSubmit={handleSubmit}
            selectedRadio={selectedRadio}
            handleRadioSelect={handleRadioSelect}
        />
    );
};

export default FormInvoiceReport;
