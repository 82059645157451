import React, { useEffect, useState } from 'react';
import axios from '../../../apiConfig';

import { useComponent } from '../../../Contexts/ComponentContext';
import { useAccountData } from '../../../Contexts/AccountDataContext';
import { useDashboard } from '../../../Contexts/DashboardContext';


//renders the details of a specific account selected by the client
const ACCOUNT_DETAILS = ({ account }) => {
    const { component, setComponent } = useComponent();
    const { selectedAccount } = useAccountData();
    const { setDashboard } = useDashboard();

    const [accountDetails, setAccountDetails] = useState({
        services: [],
        messages: []
    });

    useEffect(() => {
        setComponent('DETAILS');

        const fetchAccountDetails = async () => {
            try {
                const response = await axios.get(`/client/accountDetails?account_id=${selectedAccount.account_id}`);
                setAccountDetails(response.data);
            } catch (error) {
                console.error('Error fetching account details:', error);
            }
        };

        fetchAccountDetails();
    }, [selectedAccount, setComponent]);

    const handleBackToAccounts = () => {
        setDashboard('ACCOUNTS');
    };

    const renderAccountDetails = (component) => {
        switch (component) {
            case 'DETAILS':
                return (
                    <div>
                        <h3>Account Details</h3>
                        <p><strong>Address:</strong> {account.street_address}</p>
                        <p><strong>City:</strong> {account.city}</p>
                        <p><strong>State:</strong> {account.state}</p>
                        <p><strong>Type:</strong> {account.type}</p>
                        <p><strong>Status:</strong> {account.status}</p>
                    </div>
                );
            case 'SERVICE_HISTORY':
                return (
                    <div>
                        <h3>Service History</h3>
                        {accountDetails.services.map(service => (
                            <div key={service.service_id}>
                                <p><strong>Service Type:</strong> {service.service_type}</p>
                                <p><strong>Date:</strong> {new Date(service.timestamp).toLocaleDateString()}</p>
                                <p><strong>Amount Charged:</strong> ${parseFloat(service.amount_charged).toFixed(2)}</p>
                                <p><strong>Description:</strong> {service.description}</p>
                            </div>
                        ))}
                    </div>
                );
            case 'INVOICE_HISTORY':
                return (
                    <div>
                        <h3>Invoice History</h3>
                        {accountDetails.services
                            .filter(service => service.invoice_id)
                            .map(service => (
                                <div key={service.service_id}>
                                    <p><strong>Invoice ID:</strong> {service.invoice_id}</p>
                                    <p><strong>Service Type:</strong> {service.service_type}</p>
                                    <p><strong>Date:</strong> {new Date(service.timestamp).toLocaleDateString()}</p>
                                    <p><strong>Amount Charged:</strong> ${parseFloat(service.amount_charged).toFixed(2)}</p>
                                </div>
                        ))}
                    </div>
                );
            case 'ACCOUNT_MODIFICATIONS':
                return (
                    <div>
                        <h3>Account Update History</h3>
                        {accountDetails.messages.map(message => (
                            <div key={message.message_id}>
                                <p><strong>Date:</strong> {new Date(message.timestamp).toLocaleString()}</p>
                                <p><strong>Subject:</strong> {message.subject}</p>
                                <p><strong>Details:</strong> {message.details}</p>
                                <p><strong>Notes:</strong> {message.notes}</p>
                            </div>
                        ))}
                    </div>
                );
            default:
                return null;
        }
    };
    

    return (
        <div className="ACCOUNT_DETAILS_container">
            <div className="tabs_container">
                <div className='tabs'>
                <button className={`tab ${component === 'DETAILS' ? 'active' : ''}`} onClick={() => {
                        setComponent('DETAILS');
                    }}>Account Details</button>
                    <button className={`tab ${component === 'SERVICE_HISTORY' ? 'active' : ''}`} onClick={() => {
                        setComponent('SERVICE_HISTORY');
                    }}>Service History</button>
                    <button className={`tab ${component === 'INVOICE_HISTORY' ? 'active' : ''}`} onClick={() => {
                        setComponent('INVOICE_HISTORY');
                    }}>Invoice Items</button>
                    <button className={`tab ${component === 'ACCOUNT_MODIFICATIONS' ? 'active' : ''}`} onClick={() => {
                        setComponent('ACCOUNT_MODIFICATIONS');
                    }}>Account Update History</button>
                    <button onClick={handleBackToAccounts}>Back to Accounts</button>
                </div>
                <div className='account_content'>
                    {component === 'DETAILS' && renderAccountDetails('DETAILS')}
                    {component === 'SERVICE_HISTORY' && renderAccountDetails('SERVICE_HISTORY')}
                    {component === 'INVOICE_HISTORY' && renderAccountDetails('INVOICE_HISTORY')}
                    {component === 'ACCOUNT_MODIFICATIONS' && renderAccountDetails('ACCOUNT_MODIFICATIONS')}
                </div>
            </div>
            {renderAccountDetails()}
        </div>
    );
};

export default ACCOUNT_DETAILS;
