import React, { useState } from 'react';
import BUSINESS_DETAILS from './Description_Sections/BUSINESS_DETAILS.js';
import BUSINESS_EXAMPLES from './Description_Sections/BUSINESS_EXAMPLES.js';
import BUSINESS_STORY from './Description_Sections/BUSINESS_STORY.js';

import './SECOND_SECTION.css';


const SECOND_SECTION = () => {

    return (
        <div className='second_section_container'>
            <div className='section_container' >
                    <BUSINESS_DETAILS />,
                    <BUSINESS_STORY />,
                    <BUSINESS_EXAMPLES />
            </div>
        </div>
    );
};

export default SECOND_SECTION;
