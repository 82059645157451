import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../apiConfig';

const StageContext = createContext();

export const useStage = () => {
    const context =  useContext(StageContext);
    if (!context) {
        throw new Error('useStage must be used within a StageProvider');
    }
    return context;
}

// Handles automatic routing for a seamless and secure user experience.
export const StageProvider = ({ children }) => {
    const [stage, setStage] = useState('LAUNCH');
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('/auth/userType')
            .then(response => {
                console.log("Stage context response.data: ", response.data);
                const { userType } = response.data;
                if (userType === 'client') {
                    setStage('CLIENT');
                } else if (userType === 'admin') {
                    setStage('ADMIN');
                } else {
                    setStage('LAUNCH');
                }
            })
            .catch(error => {
                console.error('Error fetching userType:', error);
                setStage('LAUNCH');
            });


                
    }, []);

    useEffect(() => {
        switch(stage) {
            case 'LAUNCH':
                navigate('/');
                break;
            case 'CLIENT':
                navigate('/client');
                break;
            case 'ADMIN':
                navigate('/admin');
                break;
            default:
                break;
        }
    }, [stage, navigate]);

    useEffect(() => {
        const handleLogout = () => {
            setStage('LAUNCH');
        };
    
        window.addEventListener('logout-event', handleLogout);
    
        return () => {
            window.removeEventListener('logout-event', handleLogout);
        };
    }, []);
    

    const value = {
        stage,
        setStage,
    };

    return (
        <StageContext.Provider value={value}>
            {children}
        </StageContext.Provider>
    );    
}
