import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, BlobProvider } from '@react-pdf/renderer';
import logo from '../../../../../../Images/Logo-Design-Blacksmith-Revamp.png';
import getTodaysDate from '../../../../../../Utilities/Functions/getTodaysDate';
import { parseDate, parseTime } from '../../../../../../Utilities/Functions/parseDateTime';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        alignItems: 'center'
    },
    section: {
        marginTop: '.1in',
        marginBottom: '.1in',
        borderBottomWidth: 2,
        borderColor: 'black',
        borderStyle: 'solid',
        width: '90%',
    },
    logo: {
        width: '1.5in',
        height: '0.48in',
    },
    header: {
        flexDirection:'row',
        justifyContent: 'space-between',
        // borderWidth: 2,
        // borderColor: 'black',
        // borderStyle: 'solid',
        width: '100%',
        height: '0.8in'
    },
    headerBlock: {
        width: '50%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
        // borderWidth: 2,
        // borderColor: 'red',
        // borderStyle: 'solid',
    },
    title: {
        fontSize: '15pt',
        textDecoration: 'underline',
        fontWeight: 'bold',
        left: '0.25in',
        marginBottom: '.10in'
    },
    sectionDetails: {
        fontSize: '13pt',
        marginTop: '.05in',
        marginBottom: '.02in',
        width: '100%',
    },
    sectionSubDetails: {
        fontSize: '11pt',
        marginBottom: '.05in',
        left: '.2in'
    },
    layout: {
        row: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: '11pt',
            marginBottom: '.05in',
        },
        bullet: {
            height: '100%',
        }
    }
});

const ListItem = ({ children }) => {
    return (
        <View style={styles.layout.row}>
            <View style={styles.layout.bullet}>
                <Text>{'\u2022' + " "}</Text>
            </View>
            <Text style={styles.sectionSubDetails}>{children}</Text>
        </View>
    )
}

const CLIENTS_REPORT = ({ reportData }) => {

    let todaysDate = getTodaysDate(new Date());

    return (
        <PDFViewer style={{ width: '80%', height: '95%' }}>
            <Document title={reportData.length === 1 ? `Client Report: ${reportData[0].first_name} ${reportData[0].last_name} | ${todaysDate}` : `Clients Report | ${todaysDate}`} author='Sharps Lawncare' subject='Client(s) Report'>
                    {reportData.map(client => (
                        <Page size='A4' style={styles.page}>
                            <View style={styles.header}>
                                <View style={styles.headerBlock}>
                                    <Image style={[styles.logo, {left: '.25in'}]} src={logo} ></Image>
                                </View>
                                <View style={[styles.headerBlock, {fontSize: '10pt'}]}>
                                    <Text style={[{fontSize: '17pt', fontWeight: 'bold', textDecoration: 'underline'}]}>
                                    {reportData.length === 1 ? `Client Report` : `Clients Report`}
                                    </Text>
                                    <Text>
                                        {todaysDate}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ height: '.35in', width: '100%', flexDirection: 'row', justifyContent: 'center'}}>
                                <View style={{ height: '1pt', width: '90%', borderWidth: '1.5pt', borderColor: 'black', borderStyle: 'solid' , borderRadius: '5pt', backgroundColor: 'black' }}></View>
                            </View>
                            <View style={styles.section}>   
                                <View style={[{flexDirection:'row', alignItems: 'center'}]}>
                                    <Text style={styles.title}>
                                        Client:
                                    </Text>
                                    <Text style={[{left: '.35in', fontSize: '13pt', height: '85%'}]}>
                                        {client.first_name} {client.last_name}
                                    </Text>
                                </View>
                                <View style={[{flexDirection:'row', alignItems: 'center'}]} >
                                    <Text style={styles.title}>
                                        Joined:
                                    </Text>
                                    <Text style={[{left: '.35in', fontSize: '13pt', height: '85%'}]}>
                                        {parseDate(client.date_created)} {parseTime(client.date_created)}
                                    </Text>
                                </View>
                                <View style={[{flexDirection:'row', alignItems: 'center'}]} >
                                    <Text style={styles.title}>
                                        Total Charged:
                                    </Text>
                                    <Text style={[{left: '.35in', fontSize: '13pt', height: '85%'}]}>
                                        ${client.sumOfCharges === null ? '0.00' : client.sumOfCharges}
                                    </Text>
                                </View>
                                <View style={[{flexDirection:'row', alignItems: 'center'}]} >
                                    <Text style={styles.title}>
                                        {client.outstanding_debt < 0 ? 'Credit' : 'Outstanding'}:
                                    </Text>
                                    <Text style={[{left: '.35in', fontSize: '13pt', height: '85%'}]}>
                                        ${client.outstanding_debt}
                                    </Text>
                                </View>
                                <View >
                                    <Text style={styles.title}>
                                        List of Accounts:
                                    </Text>
                                        {client.accounts.map((account) => (
                                            <ListItem key={account.account_id}>
                                                {account.street_address}, {account.city}, {account.state}, {account.zip_code} | {account.type} | ${account.price} / Standard Service
                                            </ListItem>
                                        ))}
                                </View>
                            </View>
                        </Page>
                    ))}
                    
            </Document>
        </PDFViewer>
    )
}

export default CLIENTS_REPORT;