import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from '../../../apiConfig'; 


//form for paying debts to the business using Stripe
const FORM_PAYMENT = ({ paymentAmount, selectedServiceIds, onPaymentSuccess, resetPaymentAmount }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [clientSecret, setClientSecret] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(null);

    const createPaymentIntent = async () => {
        setIsProcessing(true);
        setError(null);
        try {
            const response = await axios.post('/payment/create_payment_intent', { amount: paymentAmount });
            setClientSecret(response.data.clientSecret);
            setIsProcessing(false);
        } catch (error) {
            console.error('Error creating payment intent:', error);
            setError('Error creating payment intent. Please try again.');
            setIsProcessing(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);
        setError(null);
    
        if (!stripe || !elements || !clientSecret) {
            setError('Payment service is currently unavailable. Please try again later.');
            setIsProcessing(false);
            return;
        }
    
        const cardElement = elements.getElement(CardElement);
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement
            },
        });
    
        if (error) {
            console.log('[error]', error);
            setError(error.message);
            setIsProcessing(false);
        } else {
            if (paymentIntent.status === 'succeeded') {
                console.log('Payment Success!');
                try {
                    await axios.post('/payment/success', {
                        paidServices: selectedServiceIds,
                        totalAmount: paymentAmount,
                    });
    
                    console.log('Payment processed.');
                    onPaymentSuccess();
                    resetPaymentAmount();
                } catch (error) {
                    console.error('Error in post-payment processing:', error);
                }
                setIsProcessing(false);
            }
        }
    };

    useEffect(() => {
        if (paymentAmount > 0) {
            createPaymentIntent();
        }
    }, [paymentAmount]);

    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
            {error && <div className="error-message">{error}</div>}
            <button type="submit" disabled={!stripe || !clientSecret || isProcessing}>
                {isProcessing ? 'Processing...' : 'Pay'}
            </button>
        </form>
    );
};

export default FORM_PAYMENT;
