import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
// Forms
import FormClientsReport from './Modules/Forms/FormClientsReport/FormClientsReport';
import FormAccountsReport from './Modules/Forms/FormAccountsReport/FormAccountsReport';
import FormBusinessReport from './Modules/Forms/FormBusinessReport/FormBusinessReport';
import FormInvoiceReport from './Modules/Forms/FormInvoiceReport/FormInvoiceReport';
//Reports
import BUSINESS_REPORT from './Modules/PDFs/BUSINESS_REPORT';
import INVOICE_REPORT from './Modules/PDFs/INVOICE_REPORT';
import ACCOUNTS_REPORT from './Modules/PDFs/ACCOUNTS_REPORT';
import CLIENTS_REPORT from './Modules/PDFs/CLIENTS_REPORT';
// import { CustomPDFViewer } from './Modules/PDFs/INVOICE_REPORT';

import { useComponent } from '../../../../Contexts/ComponentContext';
import { useUser } from "../../../../Contexts/UserContext";
import { useServiceData } from "../../../../Contexts/ServiceDataContext";

import Test_Document from './Test_Document';

import styles from './REPORT_GENERATION.module.css';


const REPORT_GENERATION = () => {

    const { userId, userType } = useUser();
    const { component, setComponent } = useComponent();
    const { accounts, clients, refreshBusinessData } = useServiceData();
    const [ pdfComponent, setPdfComponent ] = useState(null);
    const [ reportData, setReportData ] = useState({});

    useEffect(() => {
        setComponent('BUSINESS_REPORT');
    }, [setComponent]);

    const reportGenerationDirectories = [
        { componentName: 'BUSINESS_REPORT', buttonTitle: 'Business Report'},
        { componentName: 'ACCOUNTS_REPORT', buttonTitle: 'Account(s) Report'},
        { componentName: 'CLIENTS_REPORT', buttonTitle: 'Client(s) Report'},
        { componentName: 'INVOICE_REPORT', buttonTitle: 'Invoice Report'}
    ];

    const reportGenerationDirectoryMapping = {
        'BUSINESS_REPORT': <FormBusinessReport userId={userId} userType={userType} setPdfComponent={setPdfComponent} setReportData={setReportData} />,
        'ACCOUNTS_REPORT': <FormAccountsReport userId={userId} accounts={accounts} userType={userType} setPdfComponent={setPdfComponent} setReportData={setReportData} />,
        'CLIENTS_REPORT': <FormClientsReport userId={userId} clients={clients} userType={userType} setPdfComponent={setPdfComponent} setReportData={setReportData} />,
        'INVOICE_REPORT': <FormInvoiceReport userId={userId} userType={userType} setPdfComponent={setPdfComponent} setReportData={setReportData} />
    };

    const pdfDirectoryMapping = {
        'BUSINESS_PDF': <BUSINESS_REPORT reportData={reportData} />,
        'INVOICE_PDF': <INVOICE_REPORT reportData={reportData} />,
        'ACCOUNTS_PDF': <ACCOUNTS_REPORT reportData={reportData} />,
        'CLIENTS_PDF': <CLIENTS_REPORT reportData={reportData} />
    }

    const renderForm = () => {
        return reportGenerationDirectoryMapping[component] || null;
    }

    const renderPDF = () => {
        console.log('pdf data: ', reportData);
        return pdfDirectoryMapping[pdfComponent] || null;
    }

    return (
        <div className={styles.REPORT_GENERATION_container}>
            <div className={styles.REPORT_GENERATION_inner}>
                <div className={styles.report_selection_container}>
                    <div className={styles.tabs_container}>
                        {reportGenerationDirectories.map((directory, index) => (
                            <button key={index} className={`btn ${component === directory.componentName ? styles.active : styles.tab}`} onClick={() => {
                                setComponent(directory.componentName);
                            }}>
                                {directory.buttonTitle}
                            </button>
                        ))}
                    </div>
                    <div className={styles.forms_container}>
                        {renderForm()}
                    </div>
                </div>
                <div className={styles.pdf_container}>
                    {renderPDF()}
                </div>
            </div>
        </div>
    )

}

export default REPORT_GENERATION;