import axios from 'axios';

const apiConfig = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`, 
    withCredentials: true //every req carries cookies
});

apiConfig.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        console.log('Error response:', error);
        if (error.response.status 
            && error.response.status === 401 
            && originalRequest.url !== '/auth' 
            && originalRequest.url !== '/auth/userType' 
            && originalRequest.url !== '/auth/refreshToken' 
            && originalRequest.url !== '/log/createMessage' 
            && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const res = await apiConfig.post('/auth/refreshToken');
                if (res.status === 200) {
                    const result = await apiConfig(originalRequest);
                    return result;
                }
            } catch (err) {
                console.error('Token refresh failed:', err);
                await apiConfig.get('/auth/logout');
                window.dispatchEvent(new CustomEvent('logout-event'));
            }
        } else if (error.response.status === 403) {
            await apiConfig.get('/auth/logout');
            window.dispatchEvent(new CustomEvent('logout-event'));
        }

        return Promise.reject(error);
    }
);

export default apiConfig;
