import React, { useState, useEffect } from 'react';
import axios from '../../../../../apiConfig';
import { useServiceData } from '../../../../../Contexts/ServiceDataContext';
import { useUser } from '../../../../../Contexts/UserContext';
import logFormSubmission from '../../../../../Utilities/Requests/databaseMessage';
import { useConfirmation } from '../../../../../Contexts/Confirmation';

import styles from './CSS/update_service.module.css';

const FORM_UPDATE_SERVICE = ({ account }) => {
    const { fetchAccountsListData, fetchAccounts } = useServiceData();
    const { togglePopup, setConfirmationData, optionalWarning, setSubmitLogic, setLabels } = useConfirmation();
    const { userId, userType } = useUser();

    const [formData, setFormData] = useState({
        account_id: -1,
        status: '',
        amount_charged: '',
        serviceFrequency: '',
        serviceCount: 0,
        outstandingDebt: '',
        description: '',
        service_type: 'Mow',
        skipService: false,
        clientID: ''
    });

    const [userInterfaceMessage, setUserInterfaceMessage] = useState('');
    const [toggleUserInterfaceMessageClass, setToggleUserInterfaceMessageClass] = useState('');

    const formURI = '/admin/generateServiceWithInvoice';

    useEffect(() => {
        setFormData({
            ...formData,
            account_id: account ? account.account_id : -1,
            status: formData.skipService ? 'Skip' : account?.status,
            amount_charged: parseFloat(account?.price) || '',
            serviceFrequency: account?.service_frequency || '',
            serviceCount: account?.service_count || 0,
            outstandingDebt: parseInt(account?.outstanding_debt) || 0,
            clientID: account?.user_id || ''
        });
    }, [account, formData.skipService]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setConfirmationData({ ...formData });
        setSubmitLogic(() => async () => {
            let messageDetails = '';
            let messageError = '';
            setUserInterfaceMessage('');

            try {
                const response = await axios.post(formURI, formData);
                messageDetails = 'SUCCESSFUL: Service creation for - ';
                setToggleUserInterfaceMessageClass('success');
                setUserInterfaceMessage(response.data.message);
                fetchAccountsListData();
                fetchAccounts();
            } catch (error) {
                console.error('Error updating service:', error);
                messageDetails = `FAILURE: Service creation for - `;
                messageError = JSON.stringify(error);
                setToggleUserInterfaceMessageClass('failure');
                setUserInterfaceMessage(`${messageDetails} ${account.street_address}, ${account.city}, ${account.state}. Error: ${messageError}`);
            } finally {
                const log = {
                    senderId: userId,
                    accountId: account.account_id,
                    senderType: userType,
                    subject: 'SERVICE CREATION',
                    details: `${messageDetails} ${account.street_address}, ${account.city}, ${account.state}`,
                    notes: formData.description
                };
                logFormSubmission(log);
            }
        });
        setLabels(Object.keys(formData).map(key => ({ label: key, value: formData[key] })));
        togglePopup(true);
    };

    return (
        <div className={styles.form_container}>
            <form onSubmit={handleSubmit} className={styles.FORM_UPDATE_SERVICE_form}>
                <div className={styles.header_container}>
                    <h3 className={styles.header}>{account.first_name} {account.last_name}, {account.street_address}</h3>
                </div>
                <div className={styles.input_container}>
                    <label htmlFor='amount_charged' className={styles.label}>
                        Charge
                        <input name='amount_charged' className={`form-control ${styles.input}`} type="number" value={formData.skipService ? '0.00' : formData.amount_charged} onChange={handleInputChange} disabled={formData.skipService} />
                    </label>
                    <label htmlFor='description' className={styles.label}>
                        Details
                        <input name='description' className={`form-control ${styles.input}`} type='text' value={formData.description} onChange={handleInputChange} />
                    </label>
                    <label htmlFor='service_type' className={styles.label}>
                        Service Type
                        <select name='service_type' className={`form-select ${styles.select}`} value={formData.service_type} onChange={handleInputChange} disabled={formData.skipService}>
                            <option value="Mow">Mow</option>
                            <option value="Other">Other</option>
                        </select>
                    </label>
                    <div className={`form-check form-switch ${styles.switch_container}`}>
                        <label className={`form-check-label ${styles.switch_label}`} htmlFor="flexSwitchCheckDefault">Skip</label>
                        <input name="skipService" className={`form-check-input ${styles.switch}`} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={formData.skipService} onChange={handleInputChange} />
                    </div>
                </div>
                <div className={styles.button_container}>
                    <button className={`btn ${styles.submit_button}`} type="submit">{formData.skipService ? 'Skip Service' : 'Charge Service'}</button>
                </div>
                {userInterfaceMessage && (
                    <div className={`${styles.user_interface_message_container} ${styles[toggleUserInterfaceMessageClass]}`}>
                        {userInterfaceMessage}
                    </div>
                )}
            </form>
        </div>
    );
};

export default FORM_UPDATE_SERVICE;
