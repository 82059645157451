import React, { useState, useEffect } from 'react';
import axios from '../../../../../../apiConfig';
import FormDeleteAccountUI from './FormDeleteAccountUI';

import { useConfirmation } from '../../../../../../Contexts/Confirmation';
import logFormSubmission from '../../../../../../Utilities/Requests/databaseMessage';

export default function FormDeleteAccount({ clients, accounts, refreshBusinessData, userId, userType }) {
    const { togglePopup, setOptionalWarning, setSubmitLogic, setLabels, setConfirmationData } = useConfirmation();
    const [notes, setNotes] = useState('');
    const [formData, setFormData] = useState({ accountId: null });
    const [uiMessage, setUIMessage] = useState('');
    const [accountDetails, setAccountDetails] = useState(null);
    const [clientDetails, setClientDetails] = useState(null);

    useEffect(() => {
        if(formData.accountId){
            console.log('Form Data: ', formData);
            console.log('Accounts: ', accounts);
            const selectedAccount = accounts.find(a => a.account_id === parseInt(formData.accountId));
            console.log('Selected Account: ', selectedAccount);
            const relatedClient = clients.find(c => c.user_id === selectedAccount.user_id);
            setAccountDetails(selectedAccount);
            setClientDetails(relatedClient);
        }
    }, formData.accountId)

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Entered handleSubmit');
        setConfirmationData(formData);
        setLabels(Object.keys(formData).map(key => ({ label: key, value:formData[key] })));
        setOptionalWarning(''); // UPDATE THIS TO INCLUDE A WARNING
        let messageDetails, messageError = '';

        setSubmitLogic(() => async () => {
            try {
                const result = await axios.post('/admin/deleteAccount', formData);
                console.log('Result: ', result);
                messageDetails = 'SUCCESSFUL: Account archiving for - ';
                setUIMessage(result.data.message);
                refreshBusinessData();
            } catch (error) {
                console.error('Error deleting account: ', error);
                messageDetails = `FAILED: Client archiving for - `;
                messageError = JSON.stringify(error.response.data);
                setUIMessage(`${messageDetails} Account: ${formData.accountId}, ${accountDetails.street_address}, ${accountDetails.city}, ${accountDetails.state}. Related Client: ${clientDetails.first_name} ${clientDetails.last_name}. Error: ${messageError}`);
            } finally {
                const message = `${messageDetails} Account: ${formData.accountId}, ${accountDetails.street_address}, ${accountDetails.city}, ${accountDetails.state}. Related Client: ${clientDetails.first_name} ${clientDetails.last_name}. ${messageError ? `Error : ${messageError}` : ''}`;
                const log = {
                    senderId: userId,
                    accountId: null,
                    senderType: userType,
                    subject: 'ACCOUNT DELETION',
                    details: message,
                    notes: notes
                };
                logFormSubmission(log);
            }
        })
        togglePopup(true);
        
    }

    return (
        <FormDeleteAccountUI 
            formData={formData} 
            uiMessage={uiMessage} 
            handleSubmit={handleSubmit} 
            handleChange={handleChange} 
            notes={notes}
            setNotes={setNotes} 
            accounts={accounts} 
            accountDetails={accountDetails} 
            clientDetails={clientDetails}
        />
    )
}