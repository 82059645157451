import React from 'react';

import styles from './CSS/account_list.module.css';

const ACCOUNT_LIST = ({setComponent, component, accountsListData, setSelectedAccount}) => {

    const renderAccounts = (statuses) => {
        return accountsListData
            .filter(account => statuses.includes(account.status) && account.active === 1)
            .map(account => (
                <div className={styles.address} key={account.account_id} onClick={() => handleAccountSelect(account)}>
                    {account.street_address}, {account.city}, {account.state}
                </div>
                
            ));
    };

    const handleAccountSelect = (account) => {
        setSelectedAccount(account);
    };

    return (
        <div className={styles.list_container}>
            <div className={styles.tabs_container}>
                <div className={styles.tabs}>
                    <button className={`btn ${styles.tab} ${component === 'INCOMPLETE' ? styles.active : styles.tab}`} onClick={() => {
                        setComponent('INCOMPLETE');
                        setSelectedAccount(null);
                    }}>To-Do</button>
                    <button className={`btn ${styles.tab} ${component === 'COMPLETED' ? styles.active : styles.tab}`} onClick={() => {
                        setComponent('COMPLETED');
                        setSelectedAccount(null);
                    }}>Completed</button>
                    <button className={`btn ${styles.tab} ${component === 'SKIPPED' ? styles.active : styles.tab}`} onClick={() => {
                        setComponent('SKIPPED');
                        setSelectedAccount(null);
                    }}>Skipped</button>
                    <button className={`btn ${styles.tab} ${component === 'ALL' ? styles.active : styles.tab}`} onClick={() => {
                        setComponent('ALL');
                        setSelectedAccount(null);
                    }}>All Accounts</button>
                </div>
            </div>
            <div className={styles.account_content}>
                {component === 'INCOMPLETE' && renderAccounts(['Not Started', 'Started'])}
                {component === 'COMPLETED' && renderAccounts(['Completed'])}
                {component === 'SKIPPED' && renderAccounts(['Skipped'])}
                {component === 'ALL' && renderAccounts(['Not Started', 'Started', 'Completed','Skipped'])}
            </div>
        </div>
    )
}

export default ACCOUNT_LIST;