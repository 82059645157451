// External
import React, { useState } from 'react';

// Internal
import axios from '../../../../apiConfig';
import logActivationFormSubmission from '../../../../Utilities/Requests/activationMessage';

import styles from './CSS/FORM_ACTIVATION.module.css';

//Form for finding an existing client ——> CLIENT_ACTIVATION_FORM
const FORM_ACTIVATION = ({ handleReturn, setDashboard, setEmailToActivate, setClientCreated }) => {
    
    const [email, setEmail] = useState('');
    const [activationCounter, setActivationCounter] = useState(0);
    const [userInterfaceMessage, setUserInterfaceMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        let messageDetails = '';
        let msgErrExtension = 'Check to make sure your email is correct. If you believe there has been an error creating your account, please contact me either by phone or the contact form below.';
        let failedAttempts = activationCounter;
        setUserInterfaceMessage('');

        try {
            const { data } = await axios.post('/auth/activateByEmail', { email });
            setUserInterfaceMessage(data.message);
            setDashboard('CLIENT_ACTIVATION');
            setEmailToActivate(email);
            messageDetails = `SUCCESSFUL: Client found for ${email}.`;
            console.log(data);

        } catch (err) {
            const errorMessage = err.response?.data?.message || 'Error searching email';
            console.error("Error during the API call:", err);

            if(err.response.status === 400) {
                setActivationCounter(0);
                setUserInterfaceMessage(err.response.data.message);
                messageDetails = `FORMATTING ERROR: Email activation for ${email}`;

            } else if (err.response.status === 409) {
                setActivationCounter(0);
                setClientCreated(true);
                messageDetails = `FAILED: Email activation - ${errorMessage} for ${email}.`;

            } else if (err.response.status === 404) { 
                failedAttempts++;
                setActivationCounter(activationCounter + 1);
                messageDetails = `FAILED: Email activation - The email ${email} does not exist.`;
                failedAttempts > 1 ? setUserInterfaceMessage(errorMessage + msgErrExtension) : setUserInterfaceMessage(errorMessage);

            } else {
                messageDetails = `FAILED: Email activation - Unknown Error. Please contact developer.`

            }
        }

        const log = {
            subject: 'EMAIL ACTIVATION',
            details: messageDetails
        };

        logActivationFormSubmission(log);
    };

    return (
        <div className={styles.activation_form_container}>
            <div className={styles.return_button_container}>
                <button className={`btn ${styles.return_button}`} onClick={() => handleReturn()}>Return</button>
            </div>  
            <form onSubmit={handleSubmit} className={styles.activate_form}>
                <div className={styles.input_container}>
                    <label htmlFor='email-input' className={` ${styles.input_label}`}>
                        Email
                    </label>
                    <input 
                        type='email' 
                        className={`form-control ${styles.input_bar}`} 
                        name='email-input' 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} 
                        placeholder='example@gmail.com'
                    />
                    
                </div>
                <div className={styles.activate_button_container}>
                    <button 
                        className={`btn ${styles.activate_button}`}
                        type='submit'
                        value={'Activate'}
                    >Activate</button>
                </div>
            </form>
            {userInterfaceMessage && (<div className={styles.error_message}>{userInterfaceMessage}</div>)}

            </div>
           
    ) 
}

export default FORM_ACTIVATION;