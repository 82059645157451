import React, { useEffect, useState } from 'react';
import { useDashboard } from '../../../../Contexts/DashboardContext';
import FORM_ACTIVATION from './FORM_ACTIVATION.js';
import FORM_LOGIN from './FORM_LOGIN.js';
import FORM_CLIENT_ACTIVATION from './FORM_CLIENT_ACTIVATION';
import sharps_logo from '../../../../Images/Logo-Design-Blacksmith-Revamp.png';

import './CSS/FIRST_SECTION.css';

// The first section of the main page includes buttons to the activation and login forms, as well as a contact form
const FIRST_SECTION = () => {
    
    const { dashboard, setDashboard } = useDashboard();
    const [emailToActivate, setEmailToActivate] = useState(null);
    const [clientCreated, setClientCreated] = useState(false);
    const [message,] = useState('Email already activated');

    const handleReturn = () => {
        setDashboard('DEFAULT');
    }

    
    const ReturnButtons = () => (
        <div className='return-button-container'>
            <button className='btn first_section_button' value='Login' onClick={() => setDashboard('LOGIN')}>Login</button>
            <button className='btn first_section_button' value='Activate' onClick={() => setDashboard('ACTIVATE')}>Activate</button>
        </div>
    )

    const FormContainer = ({ children }) => (
        <div className='form-container-outer'>
            <div className='form-container-inner'>
                {children}
            </div>
        </div>
    )

    //render the launch page component (for the first section)
    const renderContent = () => {
        switch(dashboard) {
            case 'DEFAULT': return <ReturnButtons />;
            case 'ACTIVATE':
                return (
                    <FormContainer>
                        <FORM_ACTIVATION 
                            handleReturn={handleReturn}
                            setDashboard={setDashboard}
                            setClientCreated={setClientCreated}
                            setEmailToActivate={setEmailToActivate} 
                        />
                        {clientCreated && (
                            <div> {message}
                                <button value='Login' onClick={() => setDashboard('LOGIN')}>Go To Login</button>
                            </div>
                        )}
                    </FormContainer>
                );
            case 'LOGIN':
                return (
                    <FormContainer>
                        <FORM_LOGIN
                            handleReturn={handleReturn}
                            setClientCreated={setClientCreated}    
                        />
                    </FormContainer>
                );
            case 'CLIENT_ACTIVATION':
                return (
                    <FormContainer>
                        <FORM_CLIENT_ACTIVATION
                            handleReturn={handleReturn}
                            setDashboard={setDashboard}
                            setClientCreated={setClientCreated}    
                            email={emailToActivate}
                        />
                        {clientCreated && <button className='btn go_to_login' value='Login' onClick={() => setDashboard('LOGIN')}>Success! Go to Login</button>}
                    </FormContainer>
                );
            default: return null;
        }
    };

    return ( 
    <div className='first-section-container'>
        <div className='center_container' >
            <div className='logo-container'>
                <img className='main_logo' src={sharps_logo}></img>
            </div>
            <div className='form-options-container-outer'>
                <div className='form-options-container-inner'>
                    {renderContent()}
                </div>
            </div>
        </div>
        
        
    </div>
    )
};

export default FIRST_SECTION;